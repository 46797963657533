import styled from 'styled-components'
import { Theme } from '../../styles/theme'
import { darken } from 'polished'
import { TicketStatus } from '../../interfaces/tickets'

export const PaginationContainer = styled.div`
  width: 100%;
  justify-content: center;
  display: flex;
  align-items: center;
`

export const WidgetsContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: row;
  gap: 0 16px;

  & path {
    fill: ${({ theme }) => theme.colors.darkGreen};
  }
`

export const SelectDiv = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 15px;

  label {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    display: flex;
    align-items: center;
    letter-spacing: -0.05em;
    color: ${Theme.colors.quartzGrey};
    margin-bottom: 5px;
  }

  select {
    font-size: 16px;
    height: 35px;
    padding-left: 10px;
    background-color: rgb(241, 241, 241);
    border: none;
    border-radius: 4px;
  }
`

export const Error = styled.p`
  font-weight: 400;
  color: ${Theme.colors.suportError};
  font-size: 14px;
  margin-top: -11px;
  height: 15px;
`

export const Labels = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 10px;

  p {
    font-weight: 400;
    color: ${Theme.colors.suportError};
    text-align: center;
    font-size: 14px;
    cursor: pointer;
  }
`

export const Images = styled.div`
  width: 100%;
  height: 150px;
  border: 3px dashed ${Theme.colors.grayDark};
  margin-top: 10px;
  border-radius: 8px;
  display: flex;
  align-items: center;
  cursor: pointer;
  gap: 10px;
  transition: 0.3s;
  padding: 10px;
  justify-content: center;

  img {
    width: 30%;
    height: auto;
  }

  p {
    font-weight: 400;
    color: ${Theme.colors.htmlGrey};
    text-align: center;
    font-size: 16px;
    transition: 0.3s;
  }

  &:hover {
    border-color: ${Theme.colors.appleGreen};
    p {
      color: ${Theme.colors.appleGreen};
    }
  }
`

export const TicketsWrapper = styled.div`
  margin: 20px;
  display: flex;
  height: 100%;
  flex-direction: column;
  gap: 20px;

  h3 {
    color: ${({ theme }) => theme.colors.darkishGreen};
    font-weight: 300;
  }
`

export const TicketsContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
`

export const ButtonGeneric = styled.button`
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 2.5rem;
  box-shadow: inset 0px 0px 0px 0.2px rgba(255, 255, 255, 0.2);
  padding: 10px 6px 9px 6px;

  font-size: 1.125rem;
  font-weight: 800;
  line-height: 1.318125rem;
  letter-spacing: -5%;
  border: none;
  min-width: 152px;
`

export const ButtonContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`

export const ButtonsModalContainer = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 20px;
  padding-left: 42px;
  padding-right: 42px;
  gap: 16px;
`

export const SendButton = styled(ButtonGeneric)`
  background: ${(props) => props.theme.colors.darkishGreen};
  color: ${(props) => props.theme.colors.white};

  &:disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }
  &:hover {
    background-color: ${(props) => darken(0.1, props.theme.colors.darkishGreen)};
    cursor: pointer;
  }
`

export const CancelButton = styled(ButtonGeneric)`
  background: ${(props) => props.theme.colors.white};
  color: ${(props) => props.theme.colors.htmlGrey};
  border: 0.5px solid #ccc;
  &:hover {
    background-color: ${(props) => darken(0.1, props.theme.colors.white)};
    cursor: pointer;
  }
`

export const ConcludedStatus = styled.span`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 4px 8px;
  height: 30px;
  font-size: 18px;
  line-height: 14px;
  border-radius: 8px;
  color: ${({ theme }) => theme.colors.white};
  background-color: ${({ theme }) => theme.colors.darkishGreen};
`

export const PendingStatus = styled.span`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 4px 8px;
  height: 30px;
  font-size: 18px;
  line-height: 14px;
  border-radius: 8px;
  color: ${({ theme }) => theme.colors.white};
  background-color: ${({ theme }) => theme.colors.lightOrange};
`

export const PlusButton = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 4px 8px;
  height: 30px;
  width: 180px;
  font-size: 16px;
  line-height: 12px;
  border-radius: 8px;
  color: ${({ theme }) => theme.colors.white};
  background-color: ${({ theme, disabled }) =>
    disabled ? theme.colors.grey : theme.colors.darkishGreen};
`

export const CustomField = styled.textarea`
  height: 100px;
  background: #f1f1f1;
  border-radius: 8px;
  font-family: 'Roboto';
  border: none;
  padding-left: 15px;
  padding-right: 36px;
  padding-top: 8px;
  padding-bottom: 8px;
  width: 100%;
  resize: vertical;
`

const ticketStatusColors: Record<TicketStatus, string> = {
  OPEN: Theme.colors.lightOrange,
  CLOSED: Theme.colors.darkishGreen,
  IN_PROGRESS: '#CCBF00',
  WAITING_CLIENT: '#E36A00',
}

export const TicketStatusText = styled.span<{ status: TicketStatus }>`
  padding: 4px 8px;
  min-width: 100px;
  text-align: center;
  font-size: 12px;
  line-height: 14px;
  border-radius: 4px;
  color: ${({ theme }) => theme.colors.white};
  background-color: ${({ status }) => ticketStatusColors[status]};
`

export const OptionList = styled.ul<{ showDropDown?: boolean; emptyPlaceholder?: boolean }>`
  width: 124px;
  max-height: 190px;
`

export const OptionItem = styled.li<{ active?: boolean }>`
  list-style-type: none;
  cursor: pointer;
  font-family: var(--roboto);
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 19px;
  display: flex;
  align-items: center;
  letter-spacing: -0.05em;
  padding: 10px 10px 10px 13px;
  border-radius: ${({ active }) => (active ? '8px' : '0px')};
  background-color: ${({ active, theme }) =>
    active ? darken(0.07, theme.colors.white) : theme.colors.white};
  color: ${({ active, theme }) => (active ? theme.colors.darkishGreen : theme.colors.htmlGrey)};

  &:hover {
    border-radius: 8px;
    background-color: #f1f1f1;
  }
`
