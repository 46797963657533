import { Autocomplete } from '@mui/material'
import { InputV2 } from '../../../../../components/InputV2'
import { Search } from '@mui/icons-material'
import { useState } from 'react'
import { PaymentMethodPlatform } from '../../../../../interfaces/payment-method'

interface Props {
  onChange: (value?: string) => void
  value?: string
  error?: boolean
  required?: boolean
}

export default function PlatformSelect(props: Props) {
  const { value, onChange, required, error } = props

  const [selectedPlatform, setSelectedPlatform] = useState<string | undefined>(value)

  return (
    <Autocomplete
      id='plataforma'
      ListboxProps={{
        style: {
          maxHeight: '250px',
        },
      }}
      renderInput={(params) => (
        <InputV2
          rightIcon={<Search />}
          label='Plataforma'
          required={required}
          error={error}
          {...params}
        />
      )}
      options={Object.values(PaymentMethodPlatform)}
      isOptionEqualToValue={(option) => option === selectedPlatform}
      value={selectedPlatform}
      onChange={(_, value) => {
        onChange(value ?? undefined)
        setSelectedPlatform(value ?? undefined)
      }}
    />
  )
}
