import styled from 'styled-components'
import { Header } from '../../Dashboards/DashboardConsumer/styles'
export const DashboardHeader = styled(Header)`
    flex-direction: row;
    width: 100%;
    gap: 1rem;

  @media only screen and (max-width: 1700px ) {
    display: flex;
    flex-direction: column;
    }
`

export const WidgetGroup = styled.div`
    display: flex;
    flex: 1;
`
