import DescriptionRoundedIcon from '@mui/icons-material/DescriptionRounded'
import { CircularProgress } from '@mui/material'
import { useContext, useState } from 'react'
import { useDropzone } from 'react-dropzone'
import Modal from '../../../../components/Modal'
import {
  ButtonsContainer,
  CancelButton,
  SendButton,
} from '../../../../components/Modal/Payments/EditPaymentModal/styles'
import { GlobalContext } from '../../../../context/global/global'
import { uploadGenericInvoice } from '../../../../services/requests/user-requests'
import { Container, FileStatusMessage, UploadContainer, Wrapper } from './styles'

interface GenericUploadModalProps {
  isOpen: boolean
  onOpen: (open: boolean) => void
}

interface StatusFile {
  code: number
  message: string
}

export function GenericUploadModal({ isOpen, onOpen }: GenericUploadModalProps) {
  const { Theme } = useContext(GlobalContext)
  const [file, setFile] = useState<File | undefined>(undefined)
  const [statusFile, setStatusFile] = useState<StatusFile | undefined>(undefined)
  const [sendInvoiceLoading, setSendInvoiceLoading] = useState<boolean>(false)
  const { getRootProps, getInputProps, isFocused, isDragAccept, isDragReject } = useDropzone({
    maxFiles: 1,
    accept: {
      'text/html': ['.xlsx'],
    },
    onDrop: (files) => {
      const file = files?.at(0)
      setFile(file)
    },
  })

  const handleDelete = () => {
    setFile(undefined)
    setStatusFile(undefined)
  }

  const handleUploadInvoice = async () => {
    setSendInvoiceLoading(true)
    setStatusFile({ code: 1, message: 'Enviando..' })

    if (!file) return

    uploadGenericInvoice({
      invoice: file,
    })
      .then(() => {
        setStatusFile({
          code: 2,
          message: 'Sucesso'
        })
      })
      .catch((err) => {
        setStatusFile({
          code: 3,
          message: err.response?.data?.message || err.message
        })
      })
      .finally(() => {
        setSendInvoiceLoading(false)
      })
  }

  return (
    <Modal
      title='Upload do Excel'
      subtitle={
        <span>
          Para fazer o upload das faturas é necessário preencher <a href="https://docs.google.com/spreadsheets/d/1S0waHy1j77qefyXvwc3iHfIgnC7GF0FtIg7D19ZY494/edit?usp=sharing">esta planilha</a> padronizada, contate o suporte para obter mais detalhes.
        </span>
      }
      alert='Atenção para os campos do Excel obrigatórios (*)'
      openModal={isOpen}
      handleOnClose={handleDelete}
      setModalOpen={onOpen}
      defaultButtons={false}
    >
      <div>
        <UploadContainer {...getRootProps({ isFocused, isDragAccept, isDragReject })}>
          <input {...getInputProps()} />
          <p>Arraste o arquivo ou clique para fazer o upload</p>
        </UploadContainer>
        {!!file && (
          <Wrapper>
            <Container key={file?.name}>
              <DescriptionRoundedIcon />
              {file.name}
            </Container>
            {(() => {
              switch (statusFile?.code) {
                case 1:
                  return (
                    <FileStatusMessage color={Theme.colors.white} bgColor={Theme.colors.grayDark}>
                      {statusFile?.message}
                    </FileStatusMessage>
                  )
                case 2:
                  return (
                    <FileStatusMessage color={Theme.colors.white} bgColor={Theme.colors.darkishGreen}>
                      {statusFile?.message}
                    </FileStatusMessage>
                  )
                case 3:
                  return (
                    <FileStatusMessage color={Theme.colors.white} bgColor={Theme.colors.suportError}>
                      {statusFile?.message}
                    </FileStatusMessage>
                  )
              }
            })()}
          </Wrapper>
        )}
      </div>
      <ButtonsContainer>
        <CancelButton
          disabled={sendInvoiceLoading}
          type='button'
          onClick={() => {
            onOpen(false)
            handleDelete()
          }}
        >
          Fechar
        </CancelButton>
        <SendButton disabled={sendInvoiceLoading} onClick={handleUploadInvoice}>
          {sendInvoiceLoading ? <CircularProgress size={20} /> : 'Enviar'}
        </SendButton>
      </ButtonsContainer>
    </Modal>
  )
}
