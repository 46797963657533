import styled from 'styled-components'
import { useContext } from 'react'
import { GlobalContext } from '../../../../../context/global/global'

const getColor = (props: any) => {
  const { Theme } = useContext(GlobalContext)

  if (props.isDragAccept) {
    return Theme.colors.darkishGreen
  }
  if (props.isDragReject) {
    return Theme.colors.darkishGreen
  }
  if (props.isFocused) {
    return Theme.colors.darkishGreen
  }
  return '#FFF'
}

export const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 5px 20px;
`

export const Container = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
`

export const Button = styled.button`
  background-color: transparent;
  color: inherit;
  display: flex;
  align-items: center;
  justify-content: center;
`

export const UploadContainer = styled.div`
  flex: 1;
  display: table-cell;
  vertical-align: middle;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  border-width: 2px;
  height: 200px;
  width: 100%;
  font-size: 20px;
  border-radius: 2px;
  border-color: ${(props) => getColor(props)};
  border-style: dashed;
  background-color: #fafafa;
  color: #bdbdbd;
  outline: none;
  transition: border 0.24s ease-in-out;
`

export const FilesList = styled.div`
  padding-top: 20px;
  font-size: 16px;
  display: flex;
  flex-direction: column;
`

interface IFileStatusMessageProps {
  bgColor: string;
  color: string;
}

export const FileStatusMessage = styled.div<IFileStatusMessageProps>`
  padding: 4px 8px;
  font-size: 12px;
  line-height: 14px;
  border-radius: 4px;
  color: ${({ color }) => color};
  background-color: ${({ bgColor }) => bgColor};
`
