import styled from 'styled-components'
import { NavLink } from 'react-router-dom'
import { darken } from 'polished'

export const HeaderContainer = styled.header`
  height: min-content;
  margin-top: 2.8125rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 24px;
  padding: 0 20px 0 20px;
`

export const InfoRoutePath = styled.div`
  h2 {
    font-size: 1.5rem;
    font-weight: 400;
    color: ${(props) => props.theme.colors.darkishGreen};
    line-height: 2.25rem;
    font-family: ${(props) => props.theme.fonts.family.secondary};
    letter-spacing: -0.05em;
  }

  p,
  a {
    font-size: 0.875rem;
    font-weight: 400;
    color: ${(props) => props.theme.colors.htmlGrey};
    line-height: 1rem;
    letter-spacing: -0.05em;
  }

  a {
    text-decoration: none;
  }
`

export const IconsContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 2.5rem;
`

export const NotificationIcon = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  width: 3rem;
  height: 3rem;

  background: ${(props) => props.theme.colors.darkishGreen};
  border-radius: 50%;
  color: #fff;
  position: relative;

  &:hover {
    background-color: ${(props) => darken(0.1, props.theme.colors.darkishGreen)};
    cursor: pointer;
  }

  span {
    margin-bottom: -6px;
  }
`
export const Link = styled(NavLink)`
  text-decoration: none;
  color: ${(props) => props.theme.colors.htmlGrey};
`

export const IconLink = styled(Link)`
  color: ${(props) => props.theme.colors.darkishGreen};

  &:last-child {
    margin-right: 2rem;
  }
`

export const IconLinkNotification = styled(Link)`
  color: ${(props) => props.theme.colors.white};
  padding: 5px;
  border-radius: 4rem;
  width: fit-content;
  height: fit-content;
  background-color: ${(props) => props.theme.colors.darkishGreen};

  &:last-child {
    margin-right: 2rem;
  }
`
