import { GridColDef, GridRenderCellParams } from '@mui/x-data-grid'
import { AgreementAccess, CancellingAcess, DisabledAcess, ErrorAcess, PositiveAcess, StatusIcon } from './style'
import { GetConsumerUnitsColumnsParams } from './types'
import { Box, Tooltip } from '@mui/material'
import EditIcon from '../../../../assets/edit.svg'

const formatDocument = (value: string) => {
  const cnpjCpf = value.replace(/\D/g, '')

  if (cnpjCpf.length === 11) {
    return cnpjCpf.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/g, '$1.$2.$3-$4')
  }

  return cnpjCpf.replace(/(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/g, '$1.$2.$3/$4-$5')
}

export const getConsumerUnitsColumns = ({
  data,
  editHandler,
  statusHandler,
  permissions
}: GetConsumerUnitsColumnsParams): GridColDef[] => {
  const rows: GridColDef[] = [
    {
      field: 'nome',
      headerName: 'Nome',
      description: 'Nome',
      sortable: true,
      hideable: true,
      minWidth: 320
    },
    {
      field: 'codigo',
      headerName: 'Número da UC',
      description: 'Número da UC',
      sortable: true,
      hideable: true,
      minWidth: 120,
      maxWidth: 160
    },
    {
      field: 'cliente_consumidor_nome',
      headerName: 'Titular da UC',
      description: 'Titular da UC',
      sortable: true,
      hideable: true,
      minWidth: 280
    },
    {
      field: 'usina_geradora_nome',
      headerName: 'Usina Geradora',
      description: 'Usina Geradora',
      sortable: true,
      hideable: true,
      renderCell: (params: GridRenderCellParams<any, any, any>) => {
        const value = params.value?.length ? params.value : '-'

        return (
          <span
            style={{
              overflow: 'hidden',
              textOverflow: 'ellipsis',
            }}
          >
            {value}
          </span>
        )
      },
      minWidth: 280
    },
    {
      field: 'distribuidor_nome',
      headerName: 'Distribuidora',
      description: 'Distribuidora',
      sortable: true,
      hideable: true,
      minWidth: 160,
      maxWidth: 180
    },
    {
      field: 'grupo',
      headerName: 'Grupo',
      description: 'Grupo',
      hide: true,
      sortable: true,
      hideable: true,
      minWidth: 60,
    },
    {
      field: 'subgrupo',
      headerName: 'Sub Grupo',
      description: 'Sub Grupo',
      hide: true,
      sortable: false,
      hideable: true,
      minWidth: 60,
    },
    {
      field: 'documento_numero',
      headerName: 'CPF/CNPJ',
      description: 'CPF/CNPJ',
      hide: true,
      sortable: false,
      hideable: true,
      renderCell: (params: GridRenderCellParams<any, any, any>) => {
        return params.value ? formatDocument(params.value) : '-'
      },
      minWidth: 180
    },
    {
      field: 'modalidade',
      headerName: 'Modalidade',
      description: 'Modalidade',
      hide: true,
      sortable: false,
      hideable: true,
      minWidth: 180
    },
    {
      field: 'consumo_medio',
      headerName: 'Consumo médio',
      description: 'Consumo médio',
      hide: true,
      sortable: false,
      hideable: true,
      minWidth: 120
    },
    {
      field: 'metodo_pagamento_id',
      headerName: 'ID método de pagamento',
      description: 'ID método de pagamento',
      hide: true,
      sortable: false,
      hideable: true,
      minWidth: 80
    },
    {
      field: 'endereco_cep',
      headerName: 'CEP',
      description: 'CEP',
      hide: true,
      sortable: false,
      hideable: true,
      minWidth: 120
    },
    {
      field: 'endereco_logradouro',
      headerName: 'Logradouro',
      description: 'Logradouro',
      hide: true,
      sortable: false,
      hideable: true,
    },
    {
      field: 'endereco_complemento',
      headerName: 'Complemento',
      description: 'Complemento',
      hide: true,
      sortable: false,
      hideable: true,
    },
    {
      field: 'endereco_num',
      headerName: 'Numero da casa',
      description: 'Numero da casa',
      hide: true,
      sortable: false,
      hideable: true,
      minWidth: 120
    },
    {
      field: 'endereco_cidade',
      headerName: 'Cidade',
      description: 'Cidade',
      hide: true,
      sortable: false,
      hideable: true,
    },
    {
      field: 'acessivel_flag',
      headerName: 'Acesso',
      description: 'Acesso',
      renderCell: (params: GridRenderCellParams<any, any, any>) => {
        if (params.row.status === 3) return <DisabledAcess>Desligado</DisabledAcess>
        if (params.row.status === 2) return <DisabledAcess>Em cancelamento</DisabledAcess>

        return params.value ? <PositiveAcess>Ok</PositiveAcess> : <ErrorAcess>Erro</ErrorAcess>
      },
      sortable: true,
      hideable: true,
      minWidth: 80,
      maxWidth: 120
    },
    {
      field: 'status_ativacao_uc',
      headerName: 'Status de contrato',
      description: 'Status de contrato',
      renderCell(params: GridRenderCellParams<any, any, any>) {
        if (params.value === 'DISABLED') {
          return <DisabledAcess>Desativada</DisabledAcess>
        }
        if (params.value === 'CANCELLING') {
          return <CancellingAcess>Em cancelamento</CancellingAcess>
        }
        if (params.value === 'AGREEMENT') {
          return <AgreementAccess>Em acordo</AgreementAccess>
        }
        return <PositiveAcess>Ativa</PositiveAcess>
      },
      sortable: true,
      hideable: true,
      minWidth: 120,
      maxWidth: 140
    },
  ]

  if (permissions && permissions?.hasEditPermission) {
    const actionsRow: GridColDef = {
      field: 'actions',
      headerName: 'Opções',
      renderHeader: () => '',
      sortable: false,
      hideable: false,
      width: 120,
      renderCell(params: Record<'row', Record<string, unknown>>) {
        const fontSize = 15;

        return (
          <Box display={'flex'} gap={2}>
            {permissions?.hasEditPermission && (
              <Tooltip title={<p style={{ fontSize }}>Editar</p>} placement='bottom'>
                <img
                  src={EditIcon.toString()}
                  onClick={() => editHandler(params.row?.id as number, data)}
                  style={{ cursor: 'pointer' }}
                />
              </Tooltip>
            )}

            {permissions?.hasEditPermission && (
              <Tooltip
                title={<p style={{ fontSize: 15 }}>Alterar status de contrato</p>}
                placement='bottom'
              >
                <StatusIcon
                  onClick={() => statusHandler(params.row?.id as number)}
                  style={{ cursor: 'pointer' }}
                />
              </Tooltip>
            )}
          </Box>
        )
      },
    }

    rows.push(actionsRow)
  }

  return rows
}
