import { Form, FormikProvider, useFormik } from 'formik'
import Modal from '../../../../../components/ModalV2'
import * as Yup from 'yup'
import { useToast } from '../../../../../hooks/useToast'
import { useState } from 'react'
import { InputV2 } from '../../../../../components/InputV2'
import ManagerSelect from '../../../../../components/ManagerSelect'
import { Search } from '@mui/icons-material'
import DistributorSelect from './components/DistributorSelect'
import PlatformSelect from '../../../PaymentMethods/components/PlatformSelect'
import { StickyFooter } from '../../../../../components/Modal/MyUnits/Registers/ModalRegisterConsumerUnit/styles'
import { Button } from '../../../../../components/Button'
import { paymentBlockService } from '../../../../../services/payment-block'

interface FormData {
  cause: string
  client_id: number | null
  distributor_id: number | null
  platform: string | null
}

const ValidationSchema = Yup.object().shape({
  cause: Yup.string().required('Campo obrigatório'),
  client_id: Yup.number().nullable(),
  distributor_id: Yup.number().nullable(),
  platform: Yup.string().nullable(),
});

interface Props {
  onSuccess: () => void
  onClose: () => void
}

export default function PaymentBlockModal(props: Props) {
  const { onSuccess, onClose } = props

  const { toast } = useToast()

  const [isSubmitting, setSubmitting] = useState<boolean>(false)

  const handleSubmit = async (data: FormData) => {
    if (!data.client_id && !data.distributor_id && !data.platform) {
      toast({
        message: 'Pelo menos um campo entre "Cliente", "Distribuidora" ou "Plataforma" precisa ser preenchido',
        type: 'error',
      })
      return
    }
    
    try {
      setSubmitting(true)
      await paymentBlockService.create(data)
      toast({
        message: 'Bloqueio de envio criado com sucesso',
        type: 'success',
      })
      onClose()
      onSuccess()
    } catch (error) {
      toast({
        message: 'Erro ao criar bloqueio de envio',
        type: 'error',
      })
    } finally {
      setSubmitting(false)
    }
  }

  const formik = useFormik<FormData>({
    initialValues: {
      cause: '',
      platform: null,
      client_id: null,
      distributor_id: null,
    },
    validationSchema: ValidationSchema,
    onSubmit: handleSubmit,
    validateOnChange: false,
    validateOnBlur: true,
  })

  return (
    <Modal.Provider
      style={{
        display: 'flex',
        width: '100%',
        position: 'relative',
      }}
      maxWidth={500}
      isOpen
      onClose={onClose}
    >
      <Modal.Title>
        Criar Bloqueio de Envio
        <Modal.CloseButton alignLeft />
      </Modal.Title>

      <Modal.Body style={{ height: '100%', overflowY: 'auto' }}>
        <FormikProvider value={formik}>
          <Form
            style={{
              display: 'flex',
              flexDirection: 'column',
              overflowY: 'auto',
              height: '100%',
              gap: 16,
            }}
          >
            <InputV2 id='cause' label='Mensagem' required />
            <ManagerSelect
              onChange={(_, val) => formik.setFieldValue('client_id', val ?? undefined)}
              sx={{ marginBottom: 0 }}
              renderInput={(params) => (
                <InputV2 {...params} rightIcon={<Search />} id='client_id' label='Gestor' />
              )}
              />
            <DistributorSelect
              onChange={(_, val) => formik.setFieldValue('distributor_id', val ?? undefined)}
              sx={{ margin: 0 }}
              renderInput={(params) => (
                <InputV2
                  {...params}
                  rightIcon={<Search />}
                  id='distributor_id'
                  label='Distribuidora'
                />
              )}
            />
            <PlatformSelect
              required={false}
              value={formik.values.platform ?? undefined}
              error={!!formik.errors.platform}
              onChange={(value) => formik.setFieldValue('platform', value)}
            />

            <StickyFooter>
              <Button
                isLoading={isSubmitting}
                disabled={isSubmitting}
                text='Criar'
              />
            </StickyFooter>
          </Form>
        </FormikProvider>
      </Modal.Body>
    </Modal.Provider>
  )
}
