import { format, parse } from "date-fns"
import { toZonedTime } from 'date-fns-tz'

export function utcDateToTz(isoDate: string): Date | null {
  const regExp = new RegExp(/^(\d{4}-\d{2}-\d{2})T(\d{2}:\d{2}:\d{2})\.\d{3}Z$/)
  const match = isoDate.match(regExp)
  if (match) {
    const date = match[1]
    const time = match[2]
    const dateDetails = `${date} ${time}`
    return parse(
      dateDetails,
      'yyyy-MM-dd HH:mm:ss',
      toZonedTime(new Date(), 'America/Sao_Paulo'),
    )
  }
  return null
}

export function getFormattedMonthReference(monthReference: string): string {
  const parsedDate = parse(monthReference, 'yyyy-MM', new Date());
  return format(parsedDate, 'yyyy-MM-dd');
}
