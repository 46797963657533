import produce from 'immer'
import {
  ActionTypeGlobalState,
  GlobalActionType,
  GlobalStateType,
} from '../../../interfaces/states/global-state'

export function globalReducer(
  state = {} as GlobalStateType,
  action: GlobalActionType,
): GlobalStateType {
  switch (action.type) {
    case ActionTypeGlobalState.LOGIN: {
      return produce(state, (draft) => {
        draft.auth = {
          id: action.payload.id,
          token: action.payload.token,
          email: action.payload.email,
          isProvisoryPassword: action.payload.isProvisoryPassword,
          customer: action.payload.customer
            ? {
              id: action.payload.customer.id,
              type: action.payload.customer.type,
              canUpdatePayment: action.payload.customer.canUpdatePayment,
            }
            : undefined,
        }
      })
    }
    case ActionTypeGlobalState.LOGOUT: {
      return produce(state, (draft) => {
        draft.auth = undefined
      })
    }
    case ActionTypeGlobalState.SET_POWER_PLANT: {
      return produce(state, (draft) => {
        draft.powerPlants = action.payload
      })
    }
    case ActionTypeGlobalState.SET_CONSUMERS: {
      return produce(state, (draft) => {
        draft.consumers = action.payload
      })
    }
    case ActionTypeGlobalState.SET_DISTRIBUITORS: {
      return produce(state, (draft) => {
        draft.distribuitors = action.payload
      })
    }
    case ActionTypeGlobalState.SET_ALERTS: {
      return produce(state, (draft) => {
        draft.alerts = action.payload
      })
    }
    default:
      return state
  }
}

