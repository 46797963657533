import { AlertResponse } from "../../services/alerts"

export type GlobalStateType = {
  auth?: {
    id: number
    token: string
    email: string
    customer?: {
      id: number
      type: 'GESTOR' | 'CONSUMIDOR' | 'MASTER'
      canUpdatePayment: boolean
    }
    isProvisoryPassword: boolean
  }
  powerPlants?: {
    id: number
    nome: string
  }[]
  consumers?: {
    id: number
    cliente_nome: string
    documento_numero: string
  }[]
  distribuitors?: {
    id: number
    nome: string
  }[]
  alerts?: AlertResponse[]
}

export type GlobalActionType = {
  type: string
  payload?: any
}

export enum ActionTypeGlobalState {
  LOGIN = 'LOGIN',
  LOGOUT = 'LOGOUT',
  SET_POWER_PLANT = 'SET_POWER_PLANT_LIST_FILTER',
  SET_CONSUMERS = 'SET_CONSUMERS_LIST_FILTER',
  SET_DISTRIBUITORS = 'SET_DISTRIBUITORS_LIST_FILTER',
  SET_ALERTS = 'SET_ALERTS',
}
