export const ASSIGNEES: string[] = [
  'Magdiel',
  'Saulo',
  'Gabriel',
  'Jonathan',
  'Ari',
  'Marcelo',
  'Victor',
  'Mariana',
  'Allan',
  'Victor Iamasaki',
  'Jose Vitor',
  'Lidiane',
  'Jaciel'
]