import { Autocomplete, AutocompleteProps, ChipTypeMap } from '@mui/material'
import { useEffect, useState } from 'react'
import { distributorListGet } from '../../../../../../../services/requests/user-requests'
import { useToast } from '../../../../../../../hooks/useToast'

type Props = Omit<AutocompleteProps<number, false, false, false, ChipTypeMap['defaultComponent']>, 'getOptionLabel' | 'options' | ''>

export default function DistributorSelect(props: Props) {
  const { ...rest } = props

  const [distributors, setDistributors] = useState<{ nome: string; id: number }[]>([])
  const [isLoading, setLoading] = useState<boolean>(false)

  const { toast } = useToast()

  useEffect(() => {
    const fetchManagers = async () => {
      try {
        setLoading(true)
        const distributorsResponse = await distributorListGet()
        setDistributors(distributorsResponse.data)
      } catch (error) {
        toast({
          message: 'Erro ao buscar distribuidoras',
          type: 'error'
        })
      } finally {
        setLoading(false)
      }
    }
    fetchManagers()
  }, [])

  return (
    <Autocomplete
      disablePortal
      fullWidth
      sx={{ marginBottom: 2 }}
      getOptionLabel={(option) => distributors.find((manager) => manager.id === option)?.nome ?? ''}
      options={distributors.map((manager) => manager.id)}
      loading={isLoading}
      {...rest}
    />
  )
}
