import { useEffect, useState } from 'react'
import { TableV2 } from '../../../components/TableV2'
import { GridColDef } from '@mui/x-data-grid'
import { IconButton, Stack, Tooltip } from '@mui/material'
import { PaginationQuery } from '../../../interfaces/pagination'
import TableConfig from '../Dashboard/components/TableConfig'
import { GridRenderCellParams } from '@mui/x-data-grid'
import { Check } from '@mui/icons-material'
import { TicketStatusText } from '../../Tickets/styles'
import { TicketStatus } from '../../../interfaces/tickets'
import { format, parseISO } from 'date-fns'
import { DownloadDiv } from '../../Invoices/styles'
import PaymentBlockModal from './components/PaymentBlockModal'
import DeletePaymentBlockModal from './components/DeletePaymentBlockModal'
import { ListPaymentBlocks, paymentBlockService } from '../../../services/payment-block'
import { useToast } from '../../../hooks/useToast'
import Loading from '../../../components/Loading'
import { TablePagination } from '../../../components/TableV2/components/TablePagination'

export default function PanicControlSystem() {
  const [tableColumns, setTableColumns] = useState<GridColDef[]>([
    {
      field: 'id',
      headerName: 'ID',
      hideable: true,
    },
    {
      field: 'cause',
      headerName: 'Mensagem',
      hideable: true,
    },
    {
      field: 'clientName',
      headerName: 'Cliente',
      hideable: true,
    },
    {
      field: 'distributorName',
      headerName: 'Distribuidora',
      hideable: true,
    },
    {
      field: 'platform',
      headerName: 'Banco',
      hideable: true,
    },
    {
      field: 'created_at',
      headerName: 'Criado em',
      hideable: true,
      renderCell(params: GridRenderCellParams<any, any, any>) {
        if (!params.row.created_at) {
          return '-'
        }
        return format(parseISO(params.row.created_at), 'dd/MM/yyyy - hh:MM')
      },
    },
    {
      field: 'deleted_at',
      headerName: 'Removido em',
      hideable: true,
      renderCell(params: GridRenderCellParams<any, any, any>) {
        if (!params.row.deleted_at) {
          return '-'
        }
        return format(parseISO(params.row.deleted_at), 'dd/MM/yyyy - hh:MM')
      },
    },
    {
      field: 'status',
      headerName: 'Status',
      hideable: true,
      renderCell(params: GridRenderCellParams<any, any, any>) {
        if (params.row.deleted_at) {
          return <TicketStatusText status={TicketStatus.CLOSED}>Removido</TicketStatusText>
        }
        return <TicketStatusText status={TicketStatus.OPEN}>Ativo</TicketStatusText>
      },
    },
    {
      field: 'actions',
      headerName: '',
      hideable: false,
      sortable: false,
      renderCell(params: GridRenderCellParams<any, any, any>) {
        if (params.row.deleted_at) {
          return null
        }
        return (
          <Stack gap={2} direction='row'>
            <Tooltip title={<p style={{ fontSize: 15 }}>Remover bloqueio</p>} placement='bottom'>
              <IconButton onClick={() => setDeletingPaymentBlockId(params.row.id)}>
                <Check />
              </IconButton>
            </Tooltip>
          </Stack>
        )
      },
    },
  ])

  const [paginationData, setPaginationData] = useState<ListPaymentBlocks['result']>()

  const [paginationQuery, setPaginationQuery] = useState<PaginationQuery>({
    itemsPerPage: 15,
    page: 1,
  })

  const [isCreating, setCreating] = useState<boolean>(false)
  const [deletingPaymentBlockId, setDeletingPaymentBlockId] = useState<number>()

  const { toast } = useToast()

  const fetchPaymentBlocks = async () => {
    try {
      const paymentBlocks = await paymentBlockService.list(paginationQuery)
      setPaginationData(paymentBlocks)
    } catch (error) {
      toast({
        message: 'Erro ao listar bloqueios',
        type: 'error',
      })
    }
  }

  useEffect(() => {
    fetchPaymentBlocks()
  }, [paginationQuery])

  if (!paginationData) {
    return <Loading />
  }

  return (
    <>
      {deletingPaymentBlockId && (
        <DeletePaymentBlockModal
          paymentBlockId={deletingPaymentBlockId}
          onSuccess={fetchPaymentBlocks}
          onClose={() => setDeletingPaymentBlockId(undefined)}
        />
      )}
      {isCreating && (
        <PaymentBlockModal onSuccess={fetchPaymentBlocks} onClose={() => setCreating(false)} />
      )}
      <div>
        <Stack direction='row' marginLeft='auto' gap={2} marginBottom={4} marginTop={4}>
          <DownloadDiv style={{ marginLeft: 'auto' }} onClick={() => setCreating(true)}>
            Novo bloqueio
          </DownloadDiv>

          <TableConfig
            itemsPerPage={paginationQuery.itemsPerPage ?? 15}
            setItemsPerPage={(itemsPerPage) => {
              setPaginationQuery((previous) => ({
                ...previous,
                page: 1,
                itemsPerPage,
              }))
            }}
            tableColumns={tableColumns}
            setTableColumns={setTableColumns}
          />
        </Stack>
        <TableV2
          alignText='center'
          rows={paginationData.data.map((paymentBlock) => ({
            ...paymentBlock,
            clientName: paymentBlock.client?.cliente_nome ?? '-',
            platform: paymentBlock.platform ?? '-',
            distributorName: paymentBlock?.distributor?.nome ?? '-',
          }))}
          columns={tableColumns}
        >
          <TablePagination
            currentPageItemCount={paginationData.data.length}
            pageInfo={paginationData.pageInfo}
            onChange={(page) => {
              setPaginationQuery((previous) => ({
                ...previous,
                page,
              }))
            }}
          />
        </TableV2>
      </div>
    </>
  )
}
