import styled from "styled-components";
import { Alert } from "@mui/material"

export const Container = styled.div`
  .arrow {
    position: absolute;

    width: 16px;
    height: 16px;

    top: 50%;
    transform: translateY(-50%);
    -webkit-transform: translateY(-50%);

    fill: #fff;
    cursor: pointer;

    &-left {
      left: 8px;
    }

    &-right {
      right: 8px;
    }

    &-disabled {
      display: none;
    }
  }

  .current-slide {
    position: absolute;
    right: 2.5%;
    top: 50%;

    transform: translateY(-50%);
    -webkit-transform: translateY(-50%);

    font-size: 12px;
    color: ${(props) => props.theme.colors.white};
  }
`

export const Slide = styled(Alert)`
  padding: 8px 32px !important;
`
