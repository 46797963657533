import DeleteRoundedIcon from '@mui/icons-material/DeleteRounded'
import DescriptionRoundedIcon from '@mui/icons-material/DescriptionRounded'
import { CircularProgress } from '@mui/material'
import { useContext, useState } from 'react'
import { useDropzone } from 'react-dropzone'
import Modal from '../../../../components/Modal'
import {
  ButtonsContainer,
  CancelButton,
  SendButton,
} from '../../../../components/Modal/Payments/EditPaymentModal/styles'
import { v4 } from 'uuid'
import { GlobalContext } from '../../../../context/global/global'
import { uploadInvoicesNew } from '../../../../services/requests/user-requests'
import { Button, FileStatusMessage, FilesList, UploadContainer, Wrapper } from './styles'
import { useToast } from '../../../../hooks/useToast'
import { FileContainer } from '../../../Report/MultiInvoiceReader/style'

interface StatusFile {
  fileId: string
  code: number
  message: string
}

interface UploadInvoicesModalProps {
  openModal: boolean
  setOpenModal: (open: boolean) => void
}

interface StatusFile {
  code: number
  message: string
}

export function UploadInvoicesModal(props: UploadInvoicesModalProps) {
  const { openModal, setOpenModal } = props
  const { Theme } = useContext(GlobalContext)
  const { toast } = useToast()

  const [acceptedFiles, setAcceptedFiles] = useState<{ fileId: string; file: File }[]>([])
  const [statusFiles, setStatusFiles] = useState<StatusFile[]>([])
  const [sendInvoiceLoading, setSendInvoiceLoading] = useState<boolean>(false)

  const { getRootProps, getInputProps, isFocused, isDragAccept, isDragReject } = useDropzone({
    accept: {
      'text/html': ['.pdf'],
    },
    onDrop: (files: File[]) => {
      const maxFileCount = 15

      const pdfFiles = files.filter(
        (file) =>
          file.name.endsWith('.pdf') &&
          !acceptedFiles.find(
            (acceptedFile) =>
              acceptedFile.file.name === file.name && acceptedFile.file.size === file.size,
          ),
      )

      const totalPdfFiles = pdfFiles.length + acceptedFiles.length
      if (totalPdfFiles > maxFileCount) {
        toast({
          message: `O limite de arquivos para upload simultâneo é de ${maxFileCount} arquivos`,
          type: 'warning',
        })
      }

      const pdfsToSlice = maxFileCount - acceptedFiles.length
      pdfFiles.splice(pdfsToSlice - 1, pdfFiles.length - pdfsToSlice)

      setAcceptedFiles((prevFiles) => [
        ...prevFiles,
        ...pdfFiles.map((file) => ({ fileId: v4(), file })),
      ])
    },
  })

  const handleDelete = (fileId: string) => {
    setAcceptedFiles((previousAcceptedFiles) =>
      previousAcceptedFiles.filter((fileInfo) => fileInfo.fileId !== fileId),
    )
    setStatusFiles((previousStatusFiles) =>
      previousStatusFiles.filter((statusFile) => statusFile.fileId !== fileId),
    )
  }

  const deleteAll = () => {
    setAcceptedFiles([])
    setStatusFiles([])
  }

  const handleUploadInvoices = async () => {
    if (!acceptedFiles?.length) {
      toast({
        type: 'error',
        message: 'Insira pelo menos um PDF',
      })
      return
    }

    setSendInvoiceLoading(true)
    setStatusFiles(
      acceptedFiles.map((fileInfo) => ({
        fileId: fileInfo.fileId,
        code: 1,
        message: 'Enviando..',
      })),
    )

    const response = await uploadInvoicesNew({
      files: acceptedFiles,
      uploadOrigin: 'CLIENTE',
    })

    setStatusFiles(
      response.data.map((data: any) => {
        if (data.status === 'SUCCESS') {
          return { fileId: data.fileId, code: 3, message: 'Sucesso' }
        }
        if (data.status === 'INFO') {
          return { fileId: data.fileId, code: 2, message: data.message }
        }
        return { fileId: data.fileId, code: 4, message: data.message }
      }),
    )

    setSendInvoiceLoading(false)
  }

  const files = acceptedFiles.map((fileInfo, index: number) => (
    <Wrapper key={index}>
      <FileContainer key={fileInfo.file.name}>
        <DescriptionRoundedIcon />
        <span>{fileInfo.file.name}</span>
      </FileContainer>
      {(() => {
        const statusFileFound = statusFiles.find(
          (statusFile) => statusFile.fileId === fileInfo.fileId,
        )
        switch (statusFileFound?.code) {
          case 1:
            return (
              <FileStatusMessage color={Theme.colors.white} bgColor={Theme.colors.grayDark}>
                {statusFileFound.message}
              </FileStatusMessage>
            )
          case 2:
            return (
              <FileStatusMessage color={Theme.colors.white} bgColor={Theme.colors.orange}>
                {statusFileFound.message}
              </FileStatusMessage>
            )
          case 3:
            return (
              <FileStatusMessage color={Theme.colors.white} bgColor={Theme.colors.darkishGreen}>
                {statusFileFound.message}
              </FileStatusMessage>
            )
          case 4: {
            const message = statusFileFound.message
            const isPending = message === 'Fatura em analise'
            return (
              <FileStatusMessage
                color={Theme.colors.white}
                bgColor={isPending ? Theme.colors.green : Theme.colors.suportError}
              >
                {statusFileFound.message}
              </FileStatusMessage>
            )
          }
          default:
            return acceptedFiles.filter((file2) => file2.file.name === fileInfo.file.name).length >
              1 ? (
              <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                <FileStatusMessage color={Theme.colors.white} bgColor={Theme.colors.orange}>
                  Arquivo duplicado
                </FileStatusMessage>
                <Button id='file-delete-btn' onClick={() => handleDelete(fileInfo.fileId)}>
                  <DeleteRoundedIcon />
                </Button>
              </div>
            ) : (
              <Button id='file-delete-btn' onClick={() => handleDelete(fileInfo.fileId)}>
                <DeleteRoundedIcon />
              </Button>
            )
        }
      })()}
    </Wrapper>
  ))

  return (
    <Modal
      title='Upload de faturas'
      openModal={openModal}
      handleOnClose={deleteAll}
      setModalOpen={setOpenModal}
      defaultButtons={false}
    >
      <div>
        <UploadContainer {...getRootProps({ isFocused, isDragAccept, isDragReject })}>
          <input {...getInputProps()} />
          <p>Arraste o arquivo ou clique para fazer o upload</p>
        </UploadContainer>
        <FilesList>{files}</FilesList>
        <ButtonsContainer>
          <CancelButton onClick={deleteAll} style={{ gap: '5px' }}>
            Limpar arquivos
            <DeleteRoundedIcon />
          </CancelButton>
        </ButtonsContainer>
      </div>
      <ButtonsContainer>
        <CancelButton
          disabled={sendInvoiceLoading}
          type='button'
          onClick={() => {
            setOpenModal(false)
            deleteAll()
          }}
        >
          Fechar
        </CancelButton>
        <SendButton disabled={sendInvoiceLoading} onClick={handleUploadInvoices}>
          {sendInvoiceLoading ? <CircularProgress size={20} /> : 'Enviar'}
        </SendButton>
      </ButtonsContainer>
    </Modal>
  )
}
