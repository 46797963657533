import { useState, useEffect } from 'react'

import { DateFilter } from '../../../components/Filter/DateFilter'

import {
  BillingControlContainer,
  ButtonsContainer,
  CancelButton,
  SendButton,
  TableUtils,
} from './styles'

import { SettingsOutlined, Visibility } from '@mui/icons-material'
import {
  deleteInvoice,
  getAllInvoices,
  getAwsData,
  getAzureData,
  sendIaData,
} from '../../../services/requests/user-requests'
import Loading from '../../../components/Loading'
import { Alert, CircularProgress, Snackbar, TextField, Tooltip } from '@mui/material'
import { IInvoiceEditData } from '../../../services/requests/types'
import { InputSearch } from '../../../components/FormikComponents/SearchInput/styles'
import Search from '../../../assets/icons/searchIcon.svg'
import { Table } from '../../../components/Table'
import { tableColumns } from './utils'
import { FloatingCheckboxes } from '../../../components/FloatingCheckboxes'
import { ActionTableButton } from '../../../components/Table/ActionTableButton'
import Modal from '../../../components/Modal'
import { useNavigate } from 'react-router-dom'
import DistributorFilter from '../../AggregatedPayments/components/DistributorFilter'
import SearchInput from '../../Tickets/components/SearchInput'
import PreviewInvoiceModal from './components/PreviewInvoiceModal'
import { Theme } from '../../../styles/theme'

export function ReportEdit() {
  const navigate = useNavigate()

  function handleDrive(id: string) {
    const url = `${process.env.REACT_APP_BASE_URL}/faturas/download/${id}`
    window.open(url, '_blank')
  }

  const [dateSelected, setDateSelected] = useState({
    month: new Date().getMonth() + 1,
    year: new Date().getFullYear(),
  })
  const [openModal, setOpenModal] = useState(false)
  const [search, setSearch] = useState('')
  const [distributorFilter, setDistributorFilter] = useState<number[]>([])
  const [errorMessage, setErrorMessage] = useState('')
  const [isLoading, setIsLoading] = useState(false)
  const [data, setData] = useState<IInvoiceEditData['data']>([])
  const [page, setPage] = useState(1)
  const [totalCountInvoices, setTotalCountInvoices] = useState(0)
  const [deleteInvoiceId, setDeleteInvoiceId] = useState(0)
  const [pageItemsAmount, setPageItemsAmount] = useState(30)
  const [sortValues, setSortValues] = useState<{ field: string; sort: string }>()
  const [modelInvoices, setModelInvoices] = useState([
    { field: 'cliente_nome', sort: undefined },
    { field: 'potencia_instalada', sort: undefined },
    { field: 'count_ucs', sort: undefined },
    { field: 'boletos', sort: undefined },
  ])
  const [previewingFechamentoId, setPreviewFechamentoId] = useState<number>()

  const [loadingMessage, setLoadingMessage] = useState('')

  function handleReleitura(id: string, type: string) {
    ;(async () => {
      const url = `https://api.labs-lumi.com.br/faturas/show/${id}`

      if (type === 'azure') {
        setLoadingMessage('Fazendo releitura de fatura')
        setIsLoading(true)

        const invoiceData = await getAzureData(url, false)
          .then((val: any) => {
            return val.invoice
          })
          .catch(() => {
            alert('Erro ao fazer releitura')
            setIsLoading(false)
            setLoadingMessage('')
          })

        await sendIaData(invoiceData)
          .then(() => {
            alert('Releitura feita com sucesso')
          })
          .catch(() => {
            alert('Erro ao fazer releitura')
          })
          .finally(() => {
            setIsLoading(false)
            setLoadingMessage('')
          })
      } else if (type === 'aws') {
        setLoadingMessage('Fazendo releitura de fatura')
        setIsLoading(true)

        const invoiceData = await getAwsData(url)
          .then((val: any) => {
            return val.invoice
          })
          .catch(() => {
            alert('Erro ao fazer releitura')
            setIsLoading(false)
            setLoadingMessage('')
          })

        await sendIaData(invoiceData)
          .then(() => {
            alert('Releitura feita com sucesso')
          })
          .catch(() => {
            alert('Erro ao fazer releitura')
          })
          .finally(() => {
            setIsLoading(false)
            setLoadingMessage('')
          })
      }
    })()
  }

  const fetchData = async () => {
    setIsLoading(true)
    try {
      const filter = {
        ...(search ? { search } : {}),
        ...(distributorFilter.length > 0 ? { distributor: distributorFilter } : {})
      }

      const { data, count } = await getAllInvoices({
        ...dateSelected,
        page,
        limit: pageItemsAmount,
        sort: sortValues?.field,
        order: sortValues?.sort,
        ...(Object.keys(filter).length > 0 ? { filter } : {}),
      })
      setData(data ?? [])
      setTotalCountInvoices(count ?? 0)
    } catch (error) {
      setErrorMessage('Ocorreu um erro ao buscar as informações.')
      console.log('fetch data:', error)
    } finally {
      setIsLoading(false)
    }
  }

  useEffect(() => {
    fetchData()
  }, [dateSelected, sortValues, page, distributorFilter, search])

  function handleChangePage(page: number) {
    setPage(page)
  }

  return (
    <BillingControlContainer>
      {typeof previewingFechamentoId !== 'undefined' && (
        <PreviewInvoiceModal 
          previewingFechamentoId={previewingFechamentoId}
          onClose={() => setPreviewFechamentoId(undefined)}
        />
      )}
      <Modal
        title={`Remover Fatura`}
        openModal={openModal}
        setModalOpen={setOpenModal}
        defaultButtons={false}
        opacity={1}
        subtitle={'Você irá remover o fechamento, o pdf e a fatura do sistema permanentemente.'}
      >
        <ButtonsContainer>
          <CancelButton
            disabled={isLoading}
            type='button'
            onClick={() => {
              setOpenModal(false)
            }}
          >
            Cancelar
          </CancelButton>
          <SendButton
            onClick={async () => {
              setIsLoading(true)
              await deleteInvoice(deleteInvoiceId)
                .then(() => fetchData())
                .finally(() => {
                  setIsLoading(false)
                  setOpenModal(false)
                })
            }}
            disabled={isLoading}
          >
            {isLoading ? <CircularProgress size={20} /> : `Remover`}
          </SendButton>
        </ButtonsContainer>
      </Modal>
      <Snackbar
        open={errorMessage !== ''}
        autoHideDuration={5000}
        onClose={() => setErrorMessage('')}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        sx={{
          width: 400,
        }}
      >
        <Alert
          variant='filled'
          severity='error'
          onClose={() => setErrorMessage('')}
          sx={{
            width: 400,
          }}
        >
          <p>{errorMessage}</p>
        </Alert>
      </Snackbar>
      <DateFilter onChange={(value) => setDateSelected(value)} currentValue={dateSelected} />
      <TableUtils>
        <DistributorFilter
          isFilterActive
          values={distributorFilter}
          onFilter={(values) => {
            setPage(1)
            setDistributorFilter(
              values
                .filter((value) => (values.length === 0 ? false : value.checked))
                .map((value) => Number(value.id)),
            )
          }}
        />
        <SearchInput 
          style={{ marginLeft: 'auto', marginRight: '16px' }}
          onSearch={value => setSearch(value)}
        />
        <FloatingCheckboxes
          pagination={{
            selected: pageItemsAmount,
            setSelected: setPageItemsAmount,
            options: [15, 20, 30, 50, 100],
          }}
          isFilterActive={false}
          title='Configurar Tabela'
          submitBtnText='Salvar'
          customActionComponent={
            <ActionTableButton icon={<SettingsOutlined />}></ActionTableButton>
          }
          submitAction={() => fetchData()}
        />
      </TableUtils>
      {isLoading ? (
        <Loading message={loadingMessage} />
      ) : (
        <Table
          pagination={{
            page,
            total: totalCountInvoices,
            limit: pageItemsAmount,
            totalRegistersPerPage: data?.length || 0,
            onChangePage: handleChangePage,
          }}
          columns={[...tableColumns, 
            {
              field: 'preview',
              headerName: '',
              sortable: false,
              width: 45,
              renderCell: (params: any) => {
                return (
                  <Tooltip title={<p style={{ fontSize: 15 }}>Fatura de Locação</p>} placement='bottom'>
                    <Visibility
                      onClick={() => setPreviewFechamentoId(params.row.fechamento_id)}
                      style={{ cursor: 'pointer', color: Theme.colors.grayDark }}
                      fontSize='medium'
                  />
                  </Tooltip>
                )
              },
            }
          ]}
          rows={data.map((item, index) => ({ ...item, id: index }))}
          alignText='center'
          sortEvent={setSortValues}
          serverSorting
          messageNoRow='Não há faturas.'
          headerModel={modelInvoices}
          setHeaderModel={setModelInvoices}
          handleEdit={(invoiceId) => {
            const invoice = data[invoiceId]
            console.log(invoice)

            // if (invoice.nome === 'EDP SP' && invoice.grupo === 'B') {
            //   localStorage.setItem('report-page', 'edp-sp-b')
            //   navigate(`/report?update=${invoice.id}`)
            // } else if (invoice.nome === 'COPEL' && invoice.grupo === 'B') {
            //   localStorage.setItem('report-page', 'copel-b')
            //   navigate(`/report?update=${invoice.id}`)
            // } else if (invoice.nome.includes('ENERGISA') && invoice.grupo === 'B') {
            //   localStorage.setItem('report-page', 'energisa-b')
            //   navigate(`/report?update=${invoice.id}`)
            // } else {
            localStorage.setItem('report-page', 'default')
            navigate(`/report?update=${invoice.id}`)
            // }
          }}
          handlePrint={(invoiceId) => {
            const invoice = data[invoiceId]
            if (invoice && invoice.drive_id) {
              handleDrive(invoice.drive_id)
            }
          }}
          handleReloadInvoice={(invoiceId) => {
            const invoice = data[invoiceId]
            if (
              invoice &&
              invoice.drive_id &&
              (invoice.versao_leitor === 'aws' || invoice.versao_leitor === 'azure')
            ) {
              let typeInvoice = ''

              if (invoice.nome.includes('COPEL')) {
                typeInvoice = 'aws'
              } else if (
                invoice.nome.includes('ENERGISA') ||
                invoice.nome.includes('ENEL') ||
                invoice.nome.includes('EDP')
              ) {
                typeInvoice = 'azure'
              }

              if (typeInvoice) {
                handleReleitura(invoice.drive_id, typeInvoice)
              } else {
                alert('Não é possível fazer releitura por IA nessa fatura')
              }
            } else {
              alert('Não é possível fazer releitura por IA nessa fatura')
            }
          }}
          handleDelete={async (invoiceId) => {
            const invoice = data[invoiceId]

            setOpenModal(true)
            setDeleteInvoiceId(invoice.id)
          }}
        />
      )}
    </BillingControlContainer>
  )
}
