import { FormikProps } from 'formik'
import { InputV2 } from '../../../../../../InputV2'
import RemunerationRuleSelect from '../../../../components/RemunerationRuleSelect'
import { Autocomplete, Stack } from '@mui/material'
import { Search } from '@mui/icons-material'
import { MetodoDePagamentosListRequestType } from '../../../ModalRegisterConsumerUnit/types'
import { useEffect, useState } from 'react'
import { getMetodosDePagamento } from '../../../../../../../services/requests/user-requests'
import { useToast } from '../../../../../../../hooks/useToast'
import { powerPlantsCostsService } from '../../../../../../../services/power-plants-costs'
import { useOutletContext } from 'react-router-dom'
import { IMyUnitsPageProps } from '../../../../../../../pages/MyUnits/MyUnitsTables/types'
import { format, startOfMonth } from 'date-fns'

interface Props {
  formik: FormikProps<any>
  id: number | null
}

export default function RemunerationRulesFields(props: Props) {
  const { formik, id } = props
  const { errors, values, setFieldValue } = formik
  const { mes_ref } = useOutletContext<IMyUnitsPageProps>()
  const remunerationRule = formik.values.remuneracao_regra

  const [paymentMethods, setPaymentMethods] = useState<MetodoDePagamentosListRequestType>([])
  const [selectedPaymentMethodId, setSelectedPaymentMethodId] = useState<number | null>(
    values.metodo_pagamento_id,
  )

  const { toast } = useToast()

  const fetchData = async () => {
    try {
      const promises = [
        getMetodosDePagamento(), 
        powerPlantsCostsService.getPowerPlantsCosts(
          {
            monthReference: mes_ref ?? format(startOfMonth(new Date()), 'yyyy-MM-dd'),
            powerPlantId: id
          }
        )
      ]

      const [paymentMethodsData, powerPlantsCostsData] = await Promise.all(promises)

      setPaymentMethods(
        paymentMethodsData.data.map((metodo: { id: number; bb_nome_consorcio: string }) => {
          return {
            label: metodo.bb_nome_consorcio,
            value: metodo.id,
          }
        }),
      )

      if(powerPlantsCostsData) {
        setFieldValue('lease_value', powerPlantsCostsData.lease?.value)
        setFieldValue('lease_id', powerPlantsCostsData.lease?.id)
        setFieldValue('om_value', powerPlantsCostsData.om?.value)
        setFieldValue('om_id', powerPlantsCostsData.om?.id)
      }
    } catch (error) {
      console.log(error)
      toast({
        message: 'Erro ao listar dados',
        type: 'error',
      })
    }
  }

  const handleRemovePowerPlantCosts = async (id: number, type: number) => {
    try {
      await powerPlantsCostsService.deletePowerPlantsCosts(id)
      if(type === 0) {
        setFieldValue('lease_value', null)
        setFieldValue('lease_id', null)
      }
      if(type === 1) {
        setFieldValue('om_value', null)
        setFieldValue('om_id', null)
      }
      toast({
        message: 'Custo removido com sucesso',
        type: 'success',
      })
    } catch (error) {
      console.log(error)
      toast({
        message: 'Erro ao remover custo',
        type: 'error',
      })
    }
  }

  useEffect(() => {
    fetchData()
  }, [])

  useEffect(() => {
    setSelectedPaymentMethodId(values.metodo_pagamento_id)
  }, [values.metodo_pagamento_id])

  return (
    <>
      <RemunerationRuleSelect required={false} formik={formik} />

      {remunerationRule && (
        <Stack sx={{ paddingLeft: 6 }} gap={2}>
          {remunerationRule === 'PERCENTUAL_SOBRE_TARIFA_CONSUMO' && (
            <InputV2
              id='percentual_sobre_compensado_valor'
              label='Percentual sobre tarifa de consumo'
              type='number'
            />
          )}
          {(remunerationRule === 'PERCENTUAL_SOBRE_TARIFA_CRUA' ||
            remunerationRule === 'ATINS' ||
            remunerationRule === 'PERCENTUAL_SOBRE_COMPENSADO' ||
            remunerationRule === 'ENERGIA_AMIGA' ||
            remunerationRule === 'CV_CHEIA' ||
            remunerationRule === 'CV_CRUA' ||
            remunerationRule === 'PERCENTUAL_SOBRE_COMPENSADO_COSIP' ||
            remunerationRule === 'PERCENTUAL_SOBRE_COMPENSADO_DEVOLUCAO') && (
            <InputV2
              id='percentual_sobre_compensado_valor'
              label='Percentual sobre Compensado'
              type='number'
            />
          )}
          {remunerationRule === 'FFZ' && (
            <>
              <InputV2
                id='percentual_sobre_compensado_valor'
                label='Percentual sobre Compensado'
                type='number'
              />
              <InputV2 id='valor_arrendamento' label='Valor arrendamento' type='number' />
              <InputV2 id='piso_disponibilidade' label='Piso disponibilidade' type='number' />
            </>
          )}
          {(remunerationRule === 'PERCENTUAL_SOBRE_TARIFA_CRUA' ||
            remunerationRule === 'ATINS' ||
            remunerationRule === 'PERCENTUAL_SOBRE_COMPENSADO' ||
            remunerationRule === 'ENERGIA_AMIGA' ||
            remunerationRule === 'PERCENTUAL_SOBRE_TARIFA_CONSUMO' ||
            remunerationRule === 'PERCENTUAL_SOBRE_COMPENSADO_COSIP' ||
            remunerationRule === 'PERCENTUAL_SOBRE_COMPENSADO_DEVOLUCAO') && (
            <InputV2
              id='percentual_sobre_bandeira_valor'
              label='Percentual sobre Bandeira'
              type='number'
            />
          )}
          {(remunerationRule === 'DESCONTO_FIXO' ||
            remunerationRule === 'COTA_SOBRE_VALOR_FIXO') && (
            <InputV2 id='desconto_fixo_valor' label='Valor do Desconto Fixo' type='number' />
          )}
          {remunerationRule === 'TARIFA_FIXA' && (
            <InputV2 id='tarifa_fixa_valor' label='Valor da Tarifa Fixa' type='number' />
          )}
          {remunerationRule === 'VALOR_FIXO' && (
            <InputV2 id='valor_fixo' label='Valor Fixo' type='number' />
          )}
          {remunerationRule === 'SIMPLA' && (
            <>
              <InputV2
                id='percentual_sobre_compensado_valor'
                label='Percentual sobre Compensado'
                type='number'
              />
              <InputV2 id='valor_fixo' label='Valor Fixo' type='number' />
              {/* <InputV2
                    id='mes_operacao'
                    label='Data do início de operação'
                    type='text'
                    onChange={handleChangeDate}
                    value={dateMask}
                  /> */}
            </>
          )}
          {remunerationRule === 'COMPENSADO_USINA' && (
            <InputV2 id='COMPENSADO_USINA' label='Compensado Usina' type='number' />
          )}
          {remunerationRule === 'ISL_SENGI' && (
            <InputV2 id='ISL_SENGI' label='Valor ISL - SENGI' type='number' />
          )}
          {remunerationRule === 'PERCENTUAL_POR_BANDEIRA' && (
            <>
              <InputV2
                id='percentual_por_bandeira_verde'
                label='Percentual sobre Bandeira Verde'
                type='number'
              />
              <InputV2
                id='percentual_por_bandeira_amar'
                label='Percentual sobre Bandeira Amarela'
                type='number'
              />
              <InputV2
                id='percentual_por_bandeira_verm_p1'
                label='Percentual sobre Bandeira Vermelha P1'
                type='number'
              />
              <InputV2
                id='percentual_por_bandeira_verm_p2'
                label='Percentual sobre Bandeira Vermelha P2'
                type='number'
              />
            </>
          )}
          {remunerationRule === 'DESCONTO_SOBRE_FATURA_GD' ||
            (remunerationRule === 'COMPENSADO_R4' && (
              <>
                <InputV2
                  id='percentual_sobre_compensado_valor'
                  label='Percentual sobre fatura'
                  type='number'
                />
              </>
            ))}
        </Stack>
      )}

      <Autocomplete
        id='metodo_pagamento_id'
        ListboxProps={{
          style: {
            maxHeight: '250px',
          },
        }}
        renderInput={(params) => (
          <InputV2
            rightIcon={<Search />}
            error={!!errors[params.id]}
            label='Método de Pagamento'
            {...params}
          />
        )}
        options={paymentMethods.map((paymentMethod) => paymentMethod.value)}
        getOptionLabel={(option) => {
          const optionNumber = Number(option)
          return !isNaN(optionNumber)
            ? paymentMethods.find((paymentMethod) => paymentMethod.value === option)?.label ?? ''
            : String(option)
        }}
        isOptionEqualToValue={(option) => option === selectedPaymentMethodId}
        value={selectedPaymentMethodId}
        onChange={(_, value) => {
          setFieldValue('metodo_pagamento_id', value)
          setSelectedPaymentMethodId(value)
        }}
      />

      <InputV2 id='lease_value' label='Arrendamento (R$)' type='number' />
      <InputV2 id='om_value' label='O&M (Operação e Manutenção) (R$)' type='number' />
    </>
  )
}
