import ModalDelete from "../../../../components/Modal/ModalDelete"
import { useToast } from "../../../../hooks/useToast"
import { alertsService } from "../../../../services/alerts"

interface DeleteAlertModalProps {
  itemId: number
  onSuccess: () => Promise<void> 
  onClose: () => void
}

export const DeleteAlertModal = (props: DeleteAlertModalProps) => {
  const { itemId, onClose, onSuccess } = props
  const { toast } = useToast()

  const handleDeleteAlert = async () => {
    if (!itemId) {
      return
    }

    try {
      await alertsService.delete(itemId)

      toast({
        message: 'Comunicado deletado com sucesso',
        type: 'success',
      })
    } catch {
      toast({
        message: 'Erro ao deletar o comunicado',
        type: 'error',
      })
    } finally {
      await onSuccess()
    }
  }

  return (
    <ModalDelete
      openModal
      setModalOpen={onClose}
      buttonColor='darkGreen'
      text='Tem certeza que deseja excluir este comunicado?'
      textStyle={{ fontWeight: 'bold', paddingBottom: 12 }}
      descriptionStyle={{ color: 'red', paddingBottom: 12 }}
      action={handleDeleteAlert}
    />
  )
}
