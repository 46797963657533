import { api } from './api'

interface GetPowerPlantsCostsRequest {
  powerPlantId: number | null
  monthReference: string
}

export interface PowerPlantCosts {
  id: number
  power_plant_id: number
  type: string
  value: number
  month_reference: Date
  created_at: Date
  updated_at: Date
}

interface GetPowerPlantCostsResponse {
  lease: PowerPlantCosts | null
  om: PowerPlantCosts | null
}

interface CreateUpdatePowerPlantsCostsRequest {
  powerPlantId: number
  type: number
  monthReference: string
  value: number,
}

class PowerPlantsCostsService {
  async getPowerPlantsCosts(params: GetPowerPlantsCostsRequest): Promise<GetPowerPlantCostsResponse> {
    if(!params.powerPlantId) {
      return {
        lease: null,
        om: null
      }
    }
    const response = await api.get(`/power-plant-costs/power-plant/${params.powerPlantId}?monthReference=${params.monthReference}`)
    return response.data
  }
  async createPowerPlantsCosts(
    params: CreateUpdatePowerPlantsCostsRequest,
  ): Promise<void> {
    const { monthReference, powerPlantId, type, value } = params
    await api.post('/power-plant-costs/', {
      monthReference,
      powerPlantId,
      type,
      value 
    })
  }

  async updatePowerPlantsCosts(
    id: number,
    params: CreateUpdatePowerPlantsCostsRequest,
  ): Promise<void> {
    const { monthReference, powerPlantId, type, value } = params
    await api.put(`/power-plant-costs/${id}`, {
      monthReference,
      powerPlantId,
      type,
      value 
    })
  }

  async deletePowerPlantsCosts(id: number): Promise<void> {
    await api.delete(`/power-plant-costs/${id}`)
  }
}

export const powerPlantsCostsService = new PowerPlantsCostsService()
