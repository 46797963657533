import React, { ReactNode } from 'react'
import { Label } from '../Input/styles'
import {
  AlertText,
  ButtonsArea,
  CancelButton,
  ContinueButton,
  ModalBox,
  ModalContainer,
  ModalTitle,
} from './styles'

type ModalProps = {
  children: ReactNode
  title?: string
  subtitle?: ReactNode
  openModal: boolean
  setModalOpen: React.Dispatch<React.SetStateAction<boolean>> | ((open: boolean) => void)
  defaultButtons: boolean
  cancelAction?: () => void
  confirmAction?: () => void
  handleOnClose?: () => void
  hideBackDrop?: boolean
  opacity?: number
  alert?: string
  width?: string
}

export function Modal({
  children,
  title,
  openModal,
  setModalOpen,
  subtitle,
  defaultButtons,
  cancelAction,
  handleOnClose,
  confirmAction,
  hideBackDrop,
  opacity,
  width,
  alert,
}: ModalProps) {
  return (
    <ModalContainer
      open={openModal}
      onClose={() => {
        setModalOpen(!openModal)
        cancelAction && cancelAction()
        handleOnClose && handleOnClose()
      }}
      aria-labelledby='modal-modal-title'
      aria-describedby='modal-modal-description'
      hideBackdrop={hideBackDrop}
      style={{
        opacity: opacity ?? 1,
      }}
    >
      <>
        <ModalBox width={width}>
          {title && <ModalTitle>{title}</ModalTitle>}
          {subtitle && (
            <div style={{ paddingBottom: 32 }}>
              <Label mode>{subtitle}</Label>
              {alert && <AlertText>{alert}</AlertText>}
            </div>
          )}
          {children}
          {defaultButtons && (
            <ButtonsArea>
              <CancelButton
                text='Cancelar'
                mode='white'
                onClick={() => (cancelAction ? cancelAction() : null)}
              />
              <ContinueButton
                text='Continuar'
                mode='darkGreen'
                type='submit'
                onClick={() => (confirmAction ? confirmAction() : null)}
              />
            </ButtonsArea>
          )}
        </ModalBox>
      </>
    </ModalContainer>
  )
}

export default Modal
