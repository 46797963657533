import { Pagination, PaginationQuery } from '../interfaces/pagination'
import { convertObjectToQueryParams } from '../utils/pagination'
import { api } from './api'

export interface ListPaymentBlocks {
  params: PaginationQuery
  result: Pagination<{
    id: number
    cause: string
    platform: string | null
    client_id: number | null
    distributor_id: string | null
    deleted_at: string | null
    created_at: string
    updated_at: string
    distributor?: {
      nome: string
    },
    client?: {
      cliente_nome: string
    }
  }>
}

interface CreatePaymentBlock {
  params: {
    cause: string
    client_id: number | null
    distributor_id: number | null
    platform: string | null
  }
  result: void
}

interface DeletePaymentBlock {
  params: {
    paymentBlockId: number
  },
  result: void
}

export class PaymentBlockService {
  async list(params: ListPaymentBlocks['params']) {
    const { filter, itemsPerPage, page, sort } = params
    const response = await api.get('/payment-block', {
      params: {
        itemsPerPage,
        page,
        ...(filter ? { filter: convertObjectToQueryParams(filter) } : {}),
        ...(sort
          ? {
              field: sort.field,
              order: sort.order,
            }
          : {}),
      },
    })
    return response.data
  }
  async create(params: CreatePaymentBlock['params']): Promise<CreatePaymentBlock['result']> {
    await api.post('/payment-block', params)
  }
  async delete(params: DeletePaymentBlock['params']): Promise<DeletePaymentBlock['result']> {
    const { paymentBlockId } = params
    await api.delete(`/payment-block/${paymentBlockId}`)
  }
}

export const paymentBlockService = new PaymentBlockService()
