import { useContext, useState } from "react"
import { GlobalContext } from "../../../context/global/global"
import { Container, Slide } from "./style"

import 'keen-slider/keen-slider.min.css'
import { useKeenSlider } from 'keen-slider/react'

const SEVERITY_VARIANTS = {
  2: 'error',
  1: 'warning',
  0: 'info'
} as const

export const Alerts = () => {
  const [currentSlide, setCurrentSlide] = useState(0)
  const [loaded, setLoaded] = useState(false)

  const [sliderRef, instanceRef] = useKeenSlider({
    initial: 0,
    slideChanged(slider) {
      setCurrentSlide(slider.track.details.rel)
    },
    created() {
      setLoaded(true)
    },
  })

  const { state } = useContext(GlobalContext)

  const hasAlert = (state.alerts?.length ?? 0) > 0

  if (!hasAlert)
    return null

  return (
    <Container className="keen-slider" ref={sliderRef}>
      {state?.alerts?.map((item) => {
        const severity = item.severity as 0 | 1 | 2

        return (
          <Slide
            key={item.id}
            severity={SEVERITY_VARIANTS[severity]}
            variant="filled"
            className="keen-slider__slide"
            sx={{ borderRadius: 0 }}
          >
            {item.message}
          </Slide>
        )
      })}

      {loaded && instanceRef.current && (
        <>
          <Arrow
            left
            onClick={(e: any) =>
              e.stopPropagation() || instanceRef.current?.prev()
            }
            disabled={currentSlide === 0}
          />

          <Arrow
            onClick={(e: any) =>
              e.stopPropagation() || instanceRef.current?.next()
            }
            disabled={
              currentSlide ===
              instanceRef.current.track.details.slides.length - 1
            }
          />

          <span className="current-slide">
            {currentSlide + 1} / {state?.alerts?.length}
          </span>
        </>
      )}
    </Container>
  )
}

function Arrow(props: {
  disabled: boolean
  left?: boolean
  onClick: (e: any) => void
}) {
  const disabled = props.disabled ? "arrow-disabled" : ""
  return (
    <svg
      onClick={props.onClick}
      className={`arrow ${props.left ? "arrow-left" : "arrow-right"
        } ${disabled}`}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
    >
      {props.left && (
        <path d="M16.67 0l2.83 2.829-9.339 9.175 9.339 9.167-2.83 2.829-12.17-11.996z" />
      )}
      {!props.left && (
        <path d="M5 3l3.057-3 11.943 12-11.943 12-3.057-3 9-9z" />
      )}
    </svg>
  )
}

