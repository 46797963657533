import { Pagination, PaginationQuery } from '../interfaces/pagination'
import { ReaderError } from '../interfaces/reader-error'
import { convertObjectToQueryParams } from '../utils/pagination'
import { api } from './api'

export interface ListWithPagination {
  params: PaginationQuery
  result: Pagination<{
    id: number
    reader_version: string
    error_message: string
    first_occurance?: string
    last_occurance?: string
    updated_at: string
    created_at: string
    status: string
    invoiceCount: number
  }>
}
export interface DownloadFiles {
  params: {
    readerErrorId: number
  }
  result: Buffer
}
export interface DeleteFile {
  params: {
    readerErrorId: number
    errorFileId: string
  }
}
export interface ReanalyzeReaderError {
  params: {
    readerErrorId: number
    readerErrorFilesIds: string[]
  }
  result: {
    fileId: string;
    fileHash?: string;
    fileName: string;
    status: string;
    message: string;
  }[]
}
export interface Find {
  params: {
    readerErrorId: number
  }
  result: ReaderError & {
    files: {
      id: string
      name: string
      url: string
      deleted_at: string
    }[]
  }
}
export interface Delete {
  params: {
    readerErrorId: number
  }
  result: void
}

class InvoiceErrorsService {
  async listWithPagination(
    params: ListWithPagination['params'],
  ): Promise<ListWithPagination['result']> {
    const { filter, itemsPerPage, page, sort } = params
    const response = await api.get('/reader-errors/table', {
      params: {
        itemsPerPage,
        page,
        ...(filter ? { filter: convertObjectToQueryParams(filter) } : {}),
        ...(sort
          ? {
              field: sort.field,
              order: sort.order,
            }
          : {}),
      },
    })
    return response.data
  }
  async downloadErrorFiles(params: DownloadFiles['params']): Promise<DownloadFiles['result']> {
    const { readerErrorId } = params
    const response = await api.get(`/reader-errors/${readerErrorId}/files`, {
      responseType: 'arraybuffer',
    })
    return response.data
  }
  async deleteErrorFile(params: DeleteFile['params']): Promise<void> {
    const { readerErrorId, errorFileId } = params
    await api.delete(`/reader-errors/${readerErrorId}/files/${errorFileId}`)
  }
  async reanalyzeReaderError(params: ReanalyzeReaderError['params']): Promise<ReanalyzeReaderError['result']> {
    const { readerErrorId, readerErrorFilesIds } = params
    const response = await api.post(`/reader-errors/${readerErrorId}/reanalyze`, {
      readerErrorFilesIds,
    })
    return response.data
  }
  async find(params: Find['params']): Promise<Find['result']> {
    const { readerErrorId } = params
    const response = await api.get(`/reader-errors/${readerErrorId}`)
    return response.data
  }
  async delete(params: Delete['params']): Promise<Delete['result']> {
    const { readerErrorId } = params
    await api.delete(`/reader-errors/${readerErrorId}`)
  }
}

export const invoiceErrorsService = new InvoiceErrorsService()
