import ModalDelete from "../../../../../components/Modal/ModalDelete"
import { useToast } from "../../../../../hooks/useToast"
import { paymentBlockService } from "../../../../../services/payment-block"

interface Props {
  paymentBlockId: number
  onSuccess: () => void
  onClose: () => void
}

export default function DeletePaymentBlockModal(props: Props) {
  const { paymentBlockId, onSuccess, onClose } = props

  const { toast } = useToast()

  const deletePaymentBlock = async () => {
    if (!paymentBlockId) {
      return
    }
    try {
      await paymentBlockService.delete({
        paymentBlockId
      })
      toast({
        message: 'Bloqueio removido com sucesso',
        type: 'success',
      })
      onSuccess()
    } catch (error: any) {
      toast({
        message: error?.message ?? 'Erro ao remover bloqueio',
        type: 'error',
      })
    }
  }

  return (
    <ModalDelete
      openModal
      setModalOpen={onClose}
      buttonColor='darkGreen'
      confirmButtonText="Remover"
      text='Tem certeza que deseja remover o bloqueio?'
      textStyle={{ fontWeight: 'bold', paddingBottom: 12 }}
      descriptionStyle={{ color: 'red', paddingBottom: 12 }}
      action={deletePaymentBlock}
    />
  )
}