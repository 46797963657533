import { ContainerPreviewInvoice } from '../../../../Payments/styles'
import { SendButton } from '../../styles'
import Modal from '../../../../../components/ModalV2'
import { useEffect, useRef, useState } from 'react'
import { Document, Page, pdfjs } from 'react-pdf'
import 'react-pdf/dist/esm/Page/AnnotationLayer.css'
import 'react-pdf/dist/esm/Page/TextLayer.css'
import { getInvoiceDataSuper } from '../../../../../services/requests/user-requests'
import Loading from '../../../../../components/Loading'
import { StickyFooter } from '../../../../Superuser/InvoiceValidation/components/InvoiceModal/styles'
import { useToast } from '../../../../../hooks/useToast'
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`

interface invoicePreviewData {
  type: 'html' | 'pdf'
  toRender: string | { type: string; data: Buffer }
  toDownload: {
    filename: string
    content: { type: string; data: Buffer }
  }
}

const PreviewHtmlInvoice = ({ toRender }: Pick<invoicePreviewData, 'toRender'>) => {
  const ContainerPreviewInvoiceRef = useRef<HTMLDivElement | null>(null)

  useEffect(() => {
    if (ContainerPreviewInvoiceRef.current) {
      ContainerPreviewInvoiceRef.current.innerHTML = toRender as string
    }
  }, [ContainerPreviewInvoiceRef])

  return <div style={{ maxWidth: '780px' }} ref={ContainerPreviewInvoiceRef} />
}

interface Props {
  previewingFechamentoId: number
  onClose: () => void
}

export default function PreviewInvoiceModal(props: Props) {
  const { previewingFechamentoId, onClose } = props

  const [previewData, setPreviewData] = useState<invoicePreviewData>()

  const { toast } = useToast()

  async function previewInvoice() {
    try {
      const {
        data: { data },
      } = await getInvoiceDataSuper(previewingFechamentoId)

      if (data) {
        setPreviewData(data)
      }
    } catch (err: any) {
      toast({
        message: err?.message ?? 'Erro ao visualizar fatura',
        type: 'error'
      })
    }
  }

  useEffect(() => {
    previewInvoice()
  }, [])

  const downloadPreview = async (pdf: Buffer, fileName: string) => {
    if (previewData) {
      // example: https://thelearning.dev/how-to-download-files-on-button-click-reactjs

      // file object
      const file = new Blob([new Uint8Array(pdf).buffer])

      // anchor link
      const element = document.createElement('a')
      element.href = URL.createObjectURL(file)

      element.download = fileName

      // simulate link click
      document.body.appendChild(element) // Required for this to work in FireFox
      element.click()
    }
  }
  const PreviewType = ({ type, toRender }: Omit<invoicePreviewData, 'toDownload'>) => {
    switch (type) {
      case 'html':
        return <PreviewHtmlInvoice toRender={toRender as string} />

      case 'pdf':
        return (() => {
          const myBuffer = (toRender as { data: Buffer }).data
          return (
            <Document
              file={{ data: new Uint8Array(myBuffer) }}
              onSourceError={(error) => console.log('source error', error)}
              onLoadError={(error) => console.log('load error', error)}
            >
              <Page pageNumber={1} width={720} />
            </Document>
          )
        })()
    }
  }
  return (
    <Modal.Provider
      style={{
        display: 'flex',
        width: !previewData ? '100%' : 'fit-content',
        height: '100%',
        maxHeight: '750px',
        position: 'relative',
      }}
      isOpen
      onClose={onClose}
    >
      <Modal.Title>
        Visualizar Cobrança
        <Modal.CloseButton alignLeft />
      </Modal.Title>

      <Modal.Body style={{ overflowY: 'auto' }}>
        <ContainerPreviewInvoice>
          {previewData?.type && previewData?.toRender && (
            <PreviewType type={previewData.type} toRender={previewData.toRender} />
          )}
          {!previewData && (
            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
              <Loading />
            </div>
          )}
        </ContainerPreviewInvoice>
        <StickyFooter style={{ bottom: '-16px' }}>
          {previewData?.toDownload && (
            <SendButton
              style={{ width: '100%' }}
              onClick={() =>
                downloadPreview(
                  previewData.toDownload.content.data,
                  previewData.toDownload.filename,
                )
              }
            >
              Baixar PDF
            </SendButton>
          )}
        </StickyFooter>
      </Modal.Body>
    </Modal.Provider>
  )
}
