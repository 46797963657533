import { GridColDef, GridRenderCellParams } from '@mui/x-data-grid'
import {
  statusEmailStyle,
  statusPaymentStyle,
} from '../Invoices/utils/selectedObservationFieldColor'
import { AgreementAccess, CancellingAcess } from '../MyUnits/MyUnitsTables/ConsumerUnit/style'
import { DisabledAcess, PositiveAcess, RowText, StatusText, PreviewIcon } from './styles'
import { GetPaymentColumnsParams } from './types'
import { Box, Tooltip } from '@mui/material'
import EditIcon from '../../assets/edit.svg'
import PaperRedIcon from '../../assets/paper-red.svg'
import PaperIcon from '../../assets/paper.svg'
import SendIcon from '../../assets/send-gray.svg'
import NotSendIcon from '../../assets/send-red.svg'
import { documentFormater, formatDocument } from '../../utils/documentFormater'
import { formatPresentationDate } from '../../utils/formatPresentationDate'

export const getPaymentColumns = ({
  previewHandler,
  sendHandler,
  editHandler,
  printHandler,
  permissions,
  isALegacyClient = false
}: GetPaymentColumnsParams): GridColDef[] => {
  const newRows: GridColDef[] = [
    {
      field: 'mes_referencia',
      headerName: 'Mês de referência',
      description: 'Mês de referência',
      hide: true,
      sortable: true,
      hideable: true,
      renderCell: (params: GridRenderCellParams<any, any, any>) => {
        if(!params.value) return '-'
        const date = new Date(params.value)
        const formattedDate = new Intl.DateTimeFormat('pt-BR', { timeZone: 'UTC' })
        return <RowText>{formattedDate.format(date)}</RowText>
      },
      minWidth: 80,
    },

    {
      field: 'uc_documento_numero',
      headerName: 'Documento',
      description: 'Documento',
      hide: true,
      sortable: true,
      hideable: true,
      renderCell: (params: GridRenderCellParams<any, any, any>) => {
        return params.value ? formatDocument(params.value) : '-'
      },
      minWidth: 80,
    },

    {
      field: 'nome_consorcio',
      headerName: 'Consórcio/Cooperativa',
      description: 'Consórcio/Cooperativa',
      hide: true,
      sortable: true,
      hideable: true,
      renderCell: (params: GridRenderCellParams<any, any, any>) => {
        return params.value ? params.value : '-'
      },
      minWidth: 80,
    },

    {
      field: 'valor_total_fatura',
      headerName: 'Valor da Fatura da Concessionária (R$)',
      description: 'Valor da Fatura da Concessionária (R$)',
      hide: true,
      sortable: true,
      hideable: true,
      renderCell: (params: GridRenderCellParams<any, any, any>) => {
        if(!params.value) return '-'
        const formattedCurrency = new Intl.NumberFormat('pt-BR', {
          style: 'currency',
          currency: 'BRL',
        })

        return <RowText>{formattedCurrency.format(params.value).replace('R$', '')}</RowText>
      },
      minWidth: 80,
    },

    {
      field: 'envio',
      headerName: 'Data de envio',
      description: 'Data de envio',
      hide: true,
      sortable: true,
      hideable: true,
      renderCell: (params: GridRenderCellParams<any, any, any>) => {
        if(!params.value) return '-'
        const date = new Date(params.value)
        const formattedDate = new Intl.DateTimeFormat('pt-BR', { timeZone: 'UTC' })
        return <RowText>{formattedDate.format(date)}</RowText>
      },
      minWidth: 80,
    },

    {
      field: 'seu_numero',
      headerName: 'Número do documento bancário',
      description: 'Número do documento bancário',
      hide: true,
      sortable: true,
      hideable: true,
      renderCell: (params: GridRenderCellParams<any, any, any>) => {
        return params.value ? params.value : '-'
      },
      minWidth: 80,
    },

    {
      field: 'valor_pago',
      headerName: 'Valor pago (R$)',
      description: 'Valor pago (R$)',
      hide: true,
      sortable: true,
      hideable: true,
      renderCell: (params: GridRenderCellParams<any, any, any>) => {
        if(!params.value) return '-'
        const formattedCurrency = new Intl.NumberFormat('pt-BR', {
          style: 'currency',
          currency: 'BRL',
        })

        return <RowText>{formattedCurrency.format(params.value).replace('R$', '')}</RowText>
      },
      minWidth: 80,
    },

    {
      field: 'canal_pagamento',
      headerName: 'Canal de pagamento',
      description: 'Canal de pagamento',
      hide: true,
      sortable: true,
      hideable: true,
      renderCell: (params: GridRenderCellParams<any, any, any>) => {
        return params.value ? params.value : '-'
      },
      minWidth: 80,
    },

    {
      field: 'data_pagamento',
      headerName: 'Data de pagamento',
      description: 'Data de pagamento',
      hide: true,
      sortable: true,
      hideable: true,
      renderCell: (params: GridRenderCellParams<any, any, any>) => {
        if(!params.value) return '-'
        const date = new Date(params.value)
        const formattedDate = new Intl.DateTimeFormat('pt-BR', { timeZone: 'UTC' })
        return <RowText>{formattedDate.format(date)}</RowText>
      },
      minWidth: 80,
    },

    {
      field: 'consultor',
      headerName: 'Representante/Consultor',
      description: 'Representante/Consultor',
      hide: true,
      sortable: true,
      hideable: true,
      renderCell: (params: GridRenderCellParams<any, any, any>) => {
        return params.value ? params.value : '-'
      },
      minWidth: 80,
    },

    {
      field: 'client_name',
      headerName: 'Nome no Boleto',
      description: 'Nome no Boleto',
      hide: true,
      sortable: true,
      hideable: true,
      renderCell: (params: GridRenderCellParams<any, any, any>) => {
        return params.value ? params.value : '-'
      },
      minWidth: 80,
    },

    {
      field: 'client_document',
      headerName: 'CPF/CNPJ no Boleto',
      description: 'CPF/CNPJ no Boleto',
      hide: true,
      sortable: true,
      hideable: true,
      renderCell: (params: GridRenderCellParams<any, any, any>) => {
        return params.value ? params.value : '-'
      },
      minWidth: 80,
    },
  ]

  const rows: GridColDef[] = [
    {
      field: 'status',
      headerName: 'Status',
      description: 'Status',
      renderCell(params: GridRenderCellParams<any, any, any>) {
        return <StatusText className={statusPaymentStyle[params.value]}>{params.value}</StatusText>
      },
      minWidth: 90,
      maxWidth: 125,
      hideable: true
    },
    // {
    //   field: 'email_status',
    //   headerName: 'Status Email',
    //   description: 'Status Email',
    //   hideable: true,
    //   renderCell(params: GridRenderCellParams<any, any, any>) {
    //     const status_emails_sended =
    //       params.row.email_enviado && params.row.status !== 'Não cobrado'
    //         ? 'Confirmado'
    //         : 'Não enviado'
    //     if (params.value) {
    //       return (
    //         <StatusText className={statusEmailStyle[params.value]}>
    //           {params.value === 'Confirmado' && params.row.status !== 'Não cobrado'
    //             ? 'Enviado'
    //             : 'Não enviado'}
    //         </StatusText>
    //       )
    //     } else {
    //       return (
    //         <StatusText className={statusEmailStyle[status_emails_sended as any]}>
    //           {status_emails_sended === 'Confirmado' ? 'Enviado' : status_emails_sended}
    //         </StatusText>
    //       )
    //     }
    //   },
    //   minWidth: 90,
    //   maxWidth: 125
    // },
    {
      field: 'usinas',
      headerName: 'Usinas',
      description: 'Usinas',
      hide: true,
      sortable: true,
      hideable: true,
      minWidth: 260
    },
    {
      field: 'cliente_consumidor',
      headerName: 'Cliente',
      description: 'Cliente',
      hide: true,
      sortable: true,
      hideable: true,
      minWidth: 260
    },
    {
      field: 'uc_nome',
      headerName: 'Nome da UC',
      description: 'Nome da UC',
      sortable: true,
      hideable: true,
      minWidth: 320
    },
    {
      field: 'uc_codigo',
      headerName: 'Número da UC',
      description: 'Número da UC',
      sortable: true,
      hideable: true,
      minWidth: 120,
      maxWidth: 180
    },
    {
      field: 'modalidade_de_compensacao',
      headerName: 'Modalidade de compensação',
      description: 'Modalidade de compensação',
      hide: true,
      sortable: true,
      hideable: true,
      renderCell: (params: GridRenderCellParams<any, any, any>) => {
        if (params.value === 'GERACAO_COMPARTILHADA') return 'Geração Compartilhada'
        if (params.value === 'AUTOCONSUMO_LOCAL') return 'Autoconsumo Local'
        if (params.value === 'AUTOCONSUMO_REMOTO') return 'Autoconsumo Remoto'
        if (params.value === 'EMUC') return 'EMUC'
        if (params.value === 'ASSOCIACAO') return 'Associação'

        return params.value || '-'
      },
      minWidth: 160,
    },
    {
      field: 'status_ativacao_uc',
      headerName: 'Status de contrato',
      description: 'Status de contrato',
      hide: true,
      sortable: true,
      hideable: true,
      renderCell(params: GridRenderCellParams<any, any, any>) {
        if (params.value === 'DISABLED') {
          return <DisabledAcess>Desativada</DisabledAcess>
        }
        if (params.value === 'CANCELLING') {
          return <CancellingAcess>Em cancelamento</CancellingAcess>
        }
        if (params.value === 'AGREEMENT') {
          return <AgreementAccess>Em acordo</AgreementAccess>
        }
        return <PositiveAcess>Ativa</PositiveAcess>
      },
      minWidth: 60
    },
    {
      field: 'data_vencimento',
      headerName: 'Vencimento',
      description: 'Vencimento',
      sortable: true,
      hideable: true,
      renderCell(params: GridRenderCellParams<any, any, any>) {
        const date = new Date(params.value)
        const formattedDate = new Intl.DateTimeFormat('pt-BR', { timeZone: 'UTC' })
        return <RowText>{formattedDate.format(date)}</RowText>
      },
      minWidth: 80,
      maxWidth: 120
    },
    {
      field: 'remuneracao_geracao',
      headerName: 'Valor devido (R$)',
      description: 'Valor devido (R$)',
      sortable: true,
      hideable: true,
      renderCell(params: GridRenderCellParams<any, any, any>) {
        const formattedCurrency = new Intl.NumberFormat('pt-BR', {
          style: 'currency',
          currency: 'BRL',
        })

        return <RowText>{formattedCurrency.format(params.value).replace('R$', '')}</RowText>
      },
      minWidth: 80,
      maxWidth: 160
    },
    {
      field: 'consumo_total',
      headerName: 'Energia Consumida (kWh)',
      description: 'Energia Consumida (kWh)',
      hide: true,
      sortable: true,
      hideable: true,
      renderCell: (params: GridRenderCellParams<any, any, any>) => {
        return params.value ? Number(params.value.toFixed(0)).toLocaleString('pt-BR') : '-'
      },
      minWidth: 80,
    },
    {
      field: 'compensacao_total',
      headerName: 'Energia Compensada (kWh)',
      description: 'Energia Compensada (kWh)',
      hide: true,
      sortable: true,
      hideable: true,
      renderCell: (params: GridRenderCellParams<any, any, any>) => {
        return params.value ? Math.abs(Number(params.value.toFixed(0))).toLocaleString('pt-BR') : '-'
      },
      minWidth: 80,
    },
    {
      field: 'saldo_credito',
      headerName: 'Saldo de Créditos (kWh)',
      description: 'Saldo de Créditos (kWh)',
      hide: true,
      sortable: false,
      hideable: true,
      renderCell: (params: GridRenderCellParams<any, any, any>) => {
        return params.value ? Number(params.value.toFixed(0)).toLocaleString('pt-BR') : '-'
      },
      minWidth: 80,
    },
    {
      field: 'economia',
      headerName: 'Economia do Consumidor (R$)',
      description: 'Economia do Consumidor (R$)',
      hide: true,
      sortable: false,
      hideable: true,
      renderCell: (params: GridRenderCellParams<any, any, any>) => {
        if (!params.value) {
          return '-'
        }
        
        const formattedCurrency = new Intl.NumberFormat('pt-BR', {
          style: 'currency',
          currency: 'BRL',
        })

        return <RowText>{formattedCurrency.format(params.value).replace('R$', '')}</RowText>
      },
      minWidth: 80,
      maxWidth: 160,
    },
    {
      field: 'data_apresentacao',
      headerName: 'Data de apresentação',
      description: 'Data de apresentação',
      sortable: true,
      hideable: true,
      minWidth: 80,
      maxWidth: 120,
      hide: true,
      renderCell: (params: GridRenderCellParams<any, any, any>) => {
        if (!params.value) { return '-' }
        return <RowText>{formatPresentationDate(params.value)}</RowText>
      },
    },

    ...(isALegacyClient ? [] : newRows),

    {
      field: 'actions',
      headerName: 'Opções',
      renderHeader: () => '',
      sortable: false,
      hideable: false,
      width: 190,
      renderCell(params) {
        const fontSize = 15;
        const hasDriveId = !!params.row?.drive_id
        const statusToNotSend = [
          'DISABLED',
          'CANCELLING'
        ]
        const isSendBlocked = statusToNotSend.includes(params.row?.status_ativacao_uc) || params.row?.blocked_invoices || params.row?.is_blocked

        return (
          <Box display={'flex'} gap={2}>
            {permissions?.hasEditingPermission && (
              <Tooltip title={<p style={{ fontSize }}>Editar</p>} placement='bottom'>
                <img
                  src={EditIcon.toString()}
                  onClick={() => editHandler(params.row?.id as number)}
                  style={{ cursor: 'pointer' }}
                />
              </Tooltip>
            )}

            <Tooltip title={<p style={{ fontSize: 15 }}>Visualizar cobrança</p>} placement='bottom'>
              <PreviewIcon onClick={() => previewHandler(params.row.id)} style={{ cursor: 'pointer' }} />
            </Tooltip>

            {permissions?.hasSendInvoicePermission && (
              isSendBlocked ? (
                <Tooltip title={params.row?.block_message ? <p style={{ fontSize: 15 }}>{params.row?.block_message}</p> : null} placement='bottom'>
                  <img src={NotSendIcon.toString()} style={{ cursor: 'not-allowed' }} />
                </Tooltip>
              ) : (
                <Tooltip title={<p style={{ fontSize: 15 }}>Enviar cobrança</p>} placement='bottom'>
                  <img
                    src={SendIcon.toString()}
                    onClick={() => sendHandler(params.row.id)}
                    style={{ cursor: 'pointer' }}
                  />
                </Tooltip>
              )
            )}

            {
              hasDriveId ? (
                <Tooltip title={<p style={{ fontSize: 15 }}>Visualizar fatura</p>} placement='bottom'>
                  <img
                    src={PaperIcon.toString()}
                    onClick={() => printHandler(params.row?.id)}
                    style={{ cursor: 'pointer' }}
                  />
                </Tooltip>
              ) : (
                <Tooltip
                  title={<p style={{ fontSize: 15 }}>Dados obtidos a partir do demonstrativo</p>}
                  placement='bottom'
                >
                  <img
                    src={PaperRedIcon.toString()}
                    onClick={() => printHandler(params.row?.id)}
                    style={{ cursor: 'not-allowed' }}
                  />
                </Tooltip>
              )
            }
          </Box>
        )
      }
    }
  ]

  return rows
}
