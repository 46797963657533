import { useEffect, useState } from 'react'
import { invoiceErrorsService } from '../../../../../services/invoice-errors'
import { useToast } from '../../../../../hooks/useToast'
import Modal from '../../../../../components/ModalV2'
import {
  CancelButton,
  FileContainer,
  FilesList,
  FileStatusMessage,
  SendButton,
  Wrapper,
} from './styles'
import { DescriptionRounded } from '@mui/icons-material'
import { Theme } from '../../../../../styles/theme'
import { StickyFooter } from '../../../InvoiceValidation/components/InvoiceModal/styles'
import Loading from '../../../../../components/Loading'
import ModalSuccess from '../../../../../components/Modal/ModalSuccess'
import { CircularProgress } from '@mui/material'

interface StatusFile {
  fileId: string
  fileName: string
  code: number
  message: string
}

interface Props {
  readerErrorId: number
  onSuccess: () => void
  onClose: () => void
}

export default function ReanalyzeReaderErrorFilesModal(props: Props) {
  const { readerErrorId, onSuccess, onClose } = props

  const [isLoading, setLoading] = useState<boolean>(false)
  const [isReanalyzing, setReanalyzing] = useState<boolean>(false)
  const [statusFiles, setStatusFiles] = useState<StatusFile[]>([])
  const [hasCompletedAllFiles, setHasCompletedAllFiles] = useState<boolean>(false)

  const { toast } = useToast()

  const fetchReaderError = async () => {
    if (isLoading) {
      return
    }
    try {
      setLoading(true)
      const readerErrorResponse = await invoiceErrorsService.find({
        readerErrorId,
      })
      setStatusFiles(
        readerErrorResponse.files
          .filter((file) => !file.deleted_at)
          .map((file) => ({
            fileId: file.id,
            code: 0,
            message: '',
            fileName: file.name,
          })),
      )
    } catch (error: any) {
      toast({
        message: error.message ?? 'Erro ao buscar erro de leitor',
        type: 'error',
      })
    } finally {
      setLoading(false)
    }
  }

  const reanalyzeReaderError = async () => {
    try {
      setReanalyzing(true)

      const limit = 10

      const totalIterations = Math.ceil(statusFiles.length / limit)

      for (let i = 0; i < totalIterations; i++) {
        const idsToFind = statusFiles.slice(i * limit, i * limit + limit).map((file) => file.fileId)

        setStatusFiles((previous) =>
          previous.map((file) => (idsToFind.includes(file.fileId) ? { ...file, code: 1 } : file)),
        )

        const response = await invoiceErrorsService.reanalyzeReaderError({
          readerErrorId,
          readerErrorFilesIds: idsToFind,
        })

        setStatusFiles((previous) => {
          const updatedPrevious = previous.map((file) => {
            const responseData = response.find((data) => data.fileId === file.fileId)
            if (responseData) {
              if (responseData.status === 'SUCCESS') {
                return { ...file, code: 3, message: 'Sucesso' }
              } else if (responseData.status === 'INFO') {
                return { ...file, code: 2, message: responseData.message }
              } else {
                return { ...file, code: 4, message: responseData.message }
              }
            }
            return file
          })
          if (updatedPrevious.filter((file) => file.code !== 3).length === 0) {
            setHasCompletedAllFiles(true)
          }
          return updatedPrevious
        })
      }
    } catch (error: any) {
      toast({
        message: error?.message ?? 'Erro ao listar faturas com erro',
        type: 'error',
      })
    } finally {
      setReanalyzing(false)
    }
  }

  useEffect(() => {
    fetchReaderError()
  }, [])

  const files = statusFiles.map((fileInfo) => {
    return (
      <Wrapper key={`status-file-${fileInfo.fileId}`}>
        <FileContainer>
          {fileInfo?.code === 1 ? (
            <CircularProgress
              style={{ width: '20px', height: '20px', color: Theme.colors.darkishGreen }}
            />
          ) : (
            <DescriptionRounded />
          )}
          <span>{fileInfo.fileName}</span>
        </FileContainer>
        {(() => {
          switch (fileInfo?.code) {
            case 2:
              return (
                <FileStatusMessage color={Theme.colors.white} bgColor={Theme.colors.orange}>
                  {fileInfo.message}
                </FileStatusMessage>
              )
            case 3:
              return (
                <FileStatusMessage color={Theme.colors.white} bgColor={Theme.colors.darkishGreen}>
                  {fileInfo.message}
                </FileStatusMessage>
              )
            case 4: {
              const message = fileInfo.message
              const isPending = message === 'Fatura em analise'
              return (
                <FileStatusMessage
                  color={Theme.colors.white}
                  bgColor={isPending ? Theme.colors.green : Theme.colors.suportError}
                >
                  {fileInfo.message}
                </FileStatusMessage>
              )
            }
            default:
              return null
          }
        })()}
      </Wrapper>
    )
  })

  if (hasCompletedAllFiles) {
    return (
      <ModalSuccess
        text='Todas as faturas foram lidas com sucesso'
        buttonText='Ok, obrigado'
        buttonColor='darkGreen'
        openModal
        setModalOpen={() => {
          onSuccess()
          onClose()
        }}
      />
    )
  }

  return (
    <Modal.Provider
      style={{
        display: 'flex',
        height: '100%',
        width: '100%',
        maxHeight: '450px',
        position: 'relative',
      }}
      maxWidth={500}
      isOpen
      onClose={onClose}
    >
      <Modal.Title>
        Releitura de Faturas
        <Modal.CloseButton alignLeft />
      </Modal.Title>

      {isLoading && <Loading />}

      {!isLoading && (
        <>
          <Modal.Body
            style={{ display: 'flex', flexDirection: 'column', overflowY: 'auto', height: '100%' }}
          >
            <FilesList>{files}</FilesList>
          </Modal.Body>
          <StickyFooter style={{ gap: '16px', padding: '16px' }}>
            <CancelButton style={{ width: '100%' }} onClick={onClose} disabled={isReanalyzing}>
              Fechar
            </CancelButton>
            <SendButton style={{ width: '100%' }} onClick={reanalyzeReaderError} disabled={isReanalyzing}>
              Releitura
            </SendButton>
          </StickyFooter>
        </>
      )}
    </Modal.Provider>
  )
}
