import { InputLabel, Stack } from "@mui/material"
import { InputV2 } from "../../../../components/InputV2"
import Modal from "../../../../components/ModalV2"
import { Button } from "../../../../components/Button"
import { ALERT_OPTIONS, ALERT_SEVERITY } from ".."
import { ChangeEvent, useEffect, useState } from "react"
import { SelectV2 } from "../../../../components/SelectV2"
import { useToast } from "../../../../hooks/useToast"
import { alertsService } from "../../../../services/alerts"
import { distributorListGet, getAllClients } from "../../../../services/requests/user-requests"

interface CreateAlertModalProps {
  onSuccess: () => Promise<void>
  onClose: () => void
}

export const CreateAlertModal = ({ onClose, onSuccess }: CreateAlertModalProps) => {
  const { toast } = useToast()

  const [isLoading, setIsLoading] = useState(false)
  const [clients, setClients] = useState<Client[]>([])
  const [suppliers, setSuppliers] = useState<Supplier[]>([])
  const [alert, setAlert] = useState<Alert>({
    severity: 2,
    option: 'GLOBAL',
    message: '',
    client: null,
    distributor: null 
  })

  useEffect(() => {
    const fetchDefaultValues = async () => {
      const clientsResponse = await getAllClients().then(item => item.data)
      setClients(clientsResponse)

      const suppliersResponse = await distributorListGet().then(item => item.data)
      setSuppliers(suppliersResponse)
    }

    fetchDefaultValues()
  }, [])

  const handleChangeAlert = <T extends string = 'option' | 'message' | 'severity' | 'client' | 'distributor'>(
    field: T,
    value: T extends 'option' ? keyof typeof ALERT_OPTIONS :
      T extends 'severity' ? keyof typeof ALERT_SEVERITY :
      string
  ) => {
    setAlert(prev => ({
      ...prev,
      [field]: value,
    }))
  }

  const selectTypeOptions = Object.entries(ALERT_OPTIONS).map(([key, value]) => ({
    id: key,
    displayName: value
  }))

  const selectSeverityOptions = Object.entries(ALERT_SEVERITY).map(([key, value]) => ({
    id: key,
    displayName: value
  }))

  const selectClientOptions = clients.map(client => ({
    id: String(client.id),
    displayName: client.cliente_nome
  }))

  const selectSupplierOptions = suppliers.map(supplier => ({
    id: String(supplier.id),
    displayName: supplier.nome
  }))

  const handleSubmit = async () => {
    try {
      setIsLoading(true)

      if (!alert.message?.length) {
        toast({ type: 'warning', message: 'O campo de mensagem tem que estar preenchido' })
        return
      }

      const message = {
        message: alert?.message,
        option: alert?.option,
        severity: alert?.severity ?? 2
      }

      if (alert.option === 'PER_USER') {
        if (!alert.client) {
          toast({
            type: 'warning',
            message: 'É necessário selecionar um cliente'
          })
          return
        }

        Object.assign(message, {
          client: alert?.client
        })
      }

      if (alert.option === 'PER_DISTRIBUTOR') {
        if (!alert.distributor) {
          toast({
            type: 'warning',
            message: 'É necessário selecionar uma distribuidora'
          })
          return
        }

        Object.assign(message, {
          distributor: alert.distributor
        })
      }

      await alertsService.create(message)

      toast({
        type: 'success',
        message: 'O comunicado foi criado com sucesso'
      })

      await onSuccess()
    } catch {
      toast({
        type: 'error',
        message: 'Não foi possivel criar o comunicado'
      })
    } finally {
      onClose()
      setIsLoading(false)
    }
  }

  return (
    <Modal.Provider
      isOpen
      maxWidth={650}
      style={{ width: "100%" }}
      onClose={onClose}
    >
      <Modal.Title>
        {'Criar comunicado'}
        <Modal.CloseButton alignLeft />
      </Modal.Title>

      <Modal.Body>
        <Stack gap={2}>
          <Stack direction={"row"} width='100%' gap={2}>
            <Stack style={{ position: 'relative' }} gap={1} width={'50%'}>
              <InputLabel id='alert-option'>Tipo de comunicado</InputLabel>
              <SelectV2
                required
                formik={false}
                value={alert.option}
                items={selectTypeOptions}
                onChange={({ target }) => {
                  handleChangeAlert('option', target?.value as keyof typeof ALERT_OPTIONS)
                }}
              />
            </Stack>

            <Stack style={{ position: 'relative' }} gap={1} width='50%'>
              <InputLabel id='alert-option'>Gravidade do comunicado</InputLabel>
              <SelectV2
                required
                formik={false}
                value={alert.severity}
                items={selectSeverityOptions}
                onChange={({ target }) => {
                  handleChangeAlert('severity', target?.value as keyof typeof ALERT_SEVERITY)
                }}
              />
            </Stack>
          </Stack>

          {alert.option === 'PER_USER' && (
            <Stack style={{ position: 'relative' }} gap={1} width={'100%'}>
              <InputLabel id='alert-option'>Selecionar cliente</InputLabel>
              <SelectV2
                required
                formik={false}
                value={alert.client}
                items={selectClientOptions ?? []}
                onChange={({ target }) => {
                  handleChangeAlert('client', target?.value as string)
                }}
              />
            </Stack>
          )}

          {alert.option === 'PER_DISTRIBUTOR' && (
            <Stack style={{ position: 'relative' }} gap={1} width='100%'>
              <InputLabel id='alert-option'>Selecionar distribuidora</InputLabel>
              <SelectV2
                required
                formik={false}
                items={selectSupplierOptions}
                onChange={({ target }) => {
                  handleChangeAlert('distributor', String(target?.value))
                }}
              />
            </Stack>
          )}

          <InputV2
            required
            formik={false}
            label='Mensagem'
            value={alert.message}
            onChange={({ target }: ChangeEvent<HTMLInputElement>) => {
              handleChangeAlert('message', target?.value)
            }}
          />
        </Stack>

        <Stack direction={'row'} mt={3}>
          <Button
            text='Cancelar'
            onClick={onClose}
          />
          <Button
            text='Criar'
            disabled={isLoading}
            isLoading={isLoading}
            onClick={handleSubmit}
          />
        </Stack>
      </Modal.Body>
    </Modal.Provider>
  )
}

export type Client = {
  id: number | null
  cliente_nome: string
}

export type Supplier = {
  id: number | null
  nome: string
}


interface Alert {
  option: keyof typeof ALERT_OPTIONS
  severity?: keyof typeof ALERT_SEVERITY
  message: string
  distributor?: null | Supplier
  client?: null | Client
}
