import { ChevronLeft, History, SettingsOutlined, Upload } from '@mui/icons-material'
import { GridColDef } from '@mui/x-data-grid'
import { useContext, useEffect, useState } from 'react'
import DownloadIcon from '../../assets/download-icon-white.svg'
import { FloatingCheckboxes } from '../../components/FloatingCheckboxes'
import { SearchInput } from '../../components/SearchInput'
import { ActionTableButton } from '../../components/Table/ActionTableButton'
import { PowerPlantsInvoice } from '../../services/requests/types'
import {
  distributorListGet,
  invoiceDownload,
  invoiceDownloadPending,
  invoicesDistributorsGet,
  invoicesOverviewGet,
  invoicesPowerPlantsGet,
  powerPlantListGet,
} from '../../services/requests/user-requests'

import { Alert, Snackbar, Tooltip, TooltipProps, styled, tooltipClasses } from '@mui/material'
import { Link } from 'react-router-dom'
import { DateFilter } from '../../components/Filter/DateFilter'
import { FloatingFilterWrapper } from '../../components/FloatingFilterWrapper'
import Loading from '../../components/Loading'
import { GlobalContext } from '../../context/global/global'
import { CheckboxType } from '../../interfaces/checkbox'
import { InvoiceStatusColor } from '../../interfaces/invoices'
import { UploadInvoicesModal } from './components/UploadInvoicesModal'
import {
  ColorCaption,
  CompletePayment,
  DownloadDiv,
  FilterContainer,
  InvoiceCard,
  InvoiceCardBody,
  InvoiceCardCaption,
  InvoiceCardCaptionContainer,
  InvoiceHeaderCard,
  InvoiceHeaderTitleCard,
  InvoicePercentageBar,
  InvoicesContainer,
  NotLoading,
  OptionItem,
  OptionList,
  PropTitle,
  ReturnButton,
  ReturnText,
  ReturnView,
  SearchContainer,
  TableFilters,
  WaitIssuance,
} from './styles'
import { tableInvoiceColumns, tableInvoiceColumnsDistributor } from './utils/TableData'
import { colorsCaption } from './utils/selectedObservationFieldColor'
import { usePermissions } from '../../hooks/usePermissions'
import { PermissionType } from '../../interfaces/permissions'
import { TableV2 } from '../../components/TableV2'
import { TablePagination } from '../../components/TableV2/components/TablePagination'
import { Sort } from '../../interfaces/conference'
import { InvoiceExcelModal } from '../../components/Modal/InvoiceExcel'
import { GenericUploadModal } from './components/GenericUploadModal'

interface ListProps {
  id: number
  label: string
  checked: boolean
}

interface PercentagesProps {
  aguardando_emissao: number
  nao_capturadas: number
  completas: number
}

interface DownloadModalStateProps {
  isOpen: boolean
  kind: 'ALL' | 'PENDING'
}

export function Invoices() {
  const { checkPermission } = usePermissions()
  const { Theme } = useContext(GlobalContext)

  const [downloadModalState, setDownloadModalState] = useState<DownloadModalStateProps>({ isOpen: false, kind: 'ALL' })
  const [currentPowerPlantId, setCurrentPowerPlantId] = useState<{ id: number; name: string }>()
  const [status, setStatus] = useState('')
  const [page, setPage] = useState(1)
  const [search, setSearch] = useState('')
  const [sortValues, setSortValues] = useState<Sort>()
  const [tableCols, setTableCols] = useState<GridColDef[]>(
    tableInvoiceColumns({
      setCurrentId: setCurrentPowerPlantId,
      setStatus: setStatus,
      setPage: setPage,
      setSearch: setSearch,
      setSortValues: setSortValues,
    }),
  )
  const [errorMessage, setErrorMessage] = useState(undefined)
  const [tableColsDistributor, setTableColsDistributor] = useState<GridColDef[]>(
    tableInvoiceColumnsDistributor,
  )
  const [powerPlants, setPowerPlants] = useState<ListProps[]>()
  const [distributors, setDistributors] = useState<ListProps[]>()
  const [invoicePercentages, setInvoicePercentages] = useState<PercentagesProps>()
  const [eachPercentage, setEachPercentage] = useState<PercentagesProps>()
  const [powerPlantsInvoices, setPowerPlantsInvoices] = useState<PowerPlantsInvoice[]>()
  const [distributorsInvoices, setDistributorInvoices] = useState<PowerPlantsInvoice[]>()
  const [percentageTotal, setPercentageTotal] = useState(1)
  const [showDropDown, setShowDropDown] = useState(false)
  const [pageItemsAmount, setPageItemsAmount] = useState(30)

  const [dateSelected, setDateSelected] = useState({
    month: new Date().getMonth() + 1,
    year: new Date().getFullYear(),
  })

  const [powerPlantsTotal, setPowerPlantsTotal] = useState(0)
  const [distributorsTotal, setDistributorsTotal] = useState(0)
  const [ready, setReady] = useState(false)
  const [currentColor, setCurrentColor] = useState('')

  const [loading, setLoading] = useState(true)
  const [openModal, setOpenModal] = useState<boolean>(false)
  const [isGenericUploadModalOpen, setIsGenericUploadModal] = useState<boolean>(false)

  const StyledTooltip = styled(({ className, ...props }: TooltipProps) => (
    <Tooltip {...props} arrow classes={{ popper: className }} />
  ))(() => ({
    [`& .${tooltipClasses.arrow}`]: {
      color: `${currentColor}`,
    },
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: `${currentColor}`,
      color: '#FFFFFF',
      fontStyle: 'normal',
      fontFamily: 'Roboto',
      letterSpacing: 0,
      fontSize: 14,
      padding: 8,
      maxWidth: 280,
      border: '1px solid white',
    },
  }))

  useEffect(() => {
    Promise.all([powerPlantListGet(), distributorListGet()])
      .then(async ([powerPlantData, distributorData]) => {
        setLoading(!powerPlantData.data.length || !distributorData.data.length ? false : true)
        const powerPlantsList = powerPlantData

        setPowerPlants(
          powerPlantsList.data.map((item) => ({
            id: item.id,
            label: item.nome,
            checked: true,
          })),
        )
        const distribuitorList = distributorData
        setDistributors(
          distribuitorList.data.map((item: { id: number; nome: string }) => ({
            id: item.id,
            label: item.nome,
            checked: true,
          })),
        )
      })
      .catch((error) => {
        setLoading(false)
        setErrorMessage(
          error.message ??
          'Erro ao buscar os dados iniciais de usina e distribuidora.',
        )
      })
  }, [])

  function updatePagePowerPlants(currentMonth: number, currentYear: number) {
    if (powerPlants?.length && distributors?.length && !currentPowerPlantId) {
      setLoading(true)
      const allIds = {
        distributorsId: distributors.filter((item) => item.checked).map((item) => item.id),
        powerPlantsIds: powerPlants.filter((item) => item.checked).map((item) => item.id),
      }

      const request = {
        ...allIds,
        page,
        month: currentMonth,
        year: currentYear,
        status,
        filter: search,
        sort: sortValues?.field,
        order: sortValues?.order,
      }

      Promise.all([
        invoicesOverviewGet({ ...request, isPowerPlant: 'true' }),
        invoicesPowerPlantsGet({
          ...request,
          sort: sortValues?.field,
          order: sortValues?.order,
          itemsPerPage: pageItemsAmount,
        }),
      ])
        .then(async ([overviewData, powerPlantData]) => {
          setLoading(true)
          const responseOverview = overviewData
          const responsePowerPlant = powerPlantData
          setPowerPlantsTotal(responsePowerPlant.data.meta.total)

          setPowerPlantsInvoices(
            responsePowerPlant.data.data
              ? responsePowerPlant.data.data.map((item: PowerPlantsInvoice) => ({
                ...item,
                id: item.usina_id,
              }))
              : null,
          )

          setPercentageTotal(
            Number(Object.values(responseOverview.data).reduce((r: any, c: any) => r + c)),
          )
          setInvoicePercentages(responseOverview.data)
          setLoading(false)
        })
        .catch((error) => {
          setLoading(false)
          setErrorMessage(
            error.message ?? 'Erro ao buscar os dados de fatura das usinas.',
          )
        })
    }
  }

  function updatePageDistributors(currentMonth: number, currentYear: number) {
    if (currentPowerPlantId && distributors) {
      setLoading(true)
      const allIds = {
        distributorsId: distributors.filter((item) => item.checked).map((item) => item.id),
        powerPlantsIds: [currentPowerPlantId.id],
      }

      const request = {
        ...allIds,
        page,
        month: currentMonth,
        year: currentYear,
        status,
        filter: search,
      }

      Promise.all([
        invoicesOverviewGet({ ...request, isPowerPlant: 'false' }),
        invoicesDistributorsGet({
          ...request,
          sort: sortValues?.field,
          order: sortValues?.order,
          itemsPerPage: pageItemsAmount,
        }),
      ])
        .then(async ([overviewData, distributorData]) => {
          setLoading(true)
          const responseOverview = overviewData
          const responseDistributor = distributorData

          setDistributorsTotal(responseDistributor.data?.data.meta?.total)

          setDistributorInvoices(
            responseDistributor.data.data.data
              ? responseDistributor.data.data.data.map((item: PowerPlantsInvoice) => ({
                ...item,
                id: item.usina_id,
              }))
              : null,
          )

          setPercentageTotal(
            Number(Object.values(responseOverview.data).reduce((r: any, c: any) => r + c)),
          )
          setInvoicePercentages(responseOverview.data)
          setLoading(false)
        })
        .catch((error) => {
          setLoading(false)
          setErrorMessage(
            error.message ?? 'Erro ao buscar os dados de fatura das usinas.',
          )
        })
    }
  }

  useEffect(() => {
    if (!currentPowerPlantId) {
      updatePagePowerPlants(dateSelected.month, dateSelected.year)
    } else {
      updatePageDistributors(dateSelected.month, dateSelected.year)
    }
  }, [
    powerPlants,
    distributors,
    page,
    status,
    ready,
    currentPowerPlantId,
    sortValues,
    dateSelected,
    pageItemsAmount,
    tableCols,
    tableColsDistributor,
  ])

  useEffect(() => {
    if (invoicePercentages) {
      setEachPercentage({
        completas: Math.round((invoicePercentages.completas / percentageTotal) * 100),
        aguardando_emissao: Math.round(
          (invoicePercentages.aguardando_emissao / percentageTotal) * 100,
        ),
        nao_capturadas: Math.round((invoicePercentages.nao_capturadas / percentageTotal) * 100),
      })
    }
  }, [invoicePercentages])

  const handlePageChange = (nextPage: number) => {
    if (nextPage !== page) {
      setPage(nextPage)
    }
  }

  const filterDistributor = (values: any) => {
    setPage(1)
    setDistributors(values)
  }

  const handleSelectTableColumns = (checkboxes: CheckboxType[]) => {
    setTableCols((cols) =>
      cols.map((col) => ({
        ...col,
        hide: !checkboxes.find((box) => box.label === col.headerName)?.checked,
      })),
    )
    setTableColsDistributor((cols) =>
      cols.map((col) => ({
        ...col,
        hide: !checkboxes.find((box) => box.label === col.headerName)?.checked,
      })),
    )
  }

  useEffect(() => {
    if (search !== undefined) {
      const delayDebounce = setTimeout(() => {
        if (search.length >= 3 || search.length === 0) {
          setPage(1)
          setReady(!ready)
        }
      }, 1000)

      return () => clearTimeout(delayDebounce)
    }
  }, [search])

  const handleDownloadAllInvoices = async (values: string[]) => {
    const powerPlantsIds = powerPlants?.filter((item) => item.checked).map((item) => item.id)

    if (powerPlantsIds == undefined) {
      return
    }
    await invoiceDownload({
      powerPlantsIds: currentPowerPlantId ? [currentPowerPlantId.id] : powerPlantsIds,
      sort: sortValues?.field,
      order: sortValues?.order,
      month: dateSelected.month,
      year: dateSelected.year,
      filter: search,
      monthsRefs: values,
      page,
      limit: pageItemsAmount,
    })
      .then((response) => {
        const blob = new Blob([response.data])
        const element = document.createElement('a')
        element.href = URL.createObjectURL(blob)
        element.download = `faturas_${dateSelected.month + '_' + dateSelected.year}.xlsx`
        document.body.appendChild(element)
        element.click()
      })
      .catch((err) => console.log(err))
  }

  const handleDownloadPendingInvoices = async (values: string[]) => {
    const powerPlantsIds = powerPlants?.filter((item) => item.checked).map((item) => item.id)

    if (!powerPlantsIds || !powerPlantsIds?.length) {
      return
    }

    await invoiceDownloadPending({
      powerPlantsIds: currentPowerPlantId ? [currentPowerPlantId.id] : powerPlantsIds,
      sort: sortValues?.field,
      order: sortValues?.order,
      filter: search,
      month: dateSelected.month,
      year: dateSelected.year,
      monthsRefs: values,
      page,
      limit: pageItemsAmount,
    })
      .then((response) => {
        const blob = new Blob([response.data])
        const element = document.createElement('a')
        element.href = URL.createObjectURL(blob)
        element.download = `faturas_${dateSelected.month + '_' + dateSelected.year}.xlsx`
        document.body.appendChild(element)
        element.click()
      })
      .catch((err) => console.log(err))
  }

  const handleUpdateDownloadModal = (kind: DownloadModalStateProps['kind']) => setDownloadModalState(prev => ({
    isOpen: !prev.isOpen,
    kind
  }))
  const handleToggleModal = (isOpen: boolean) => setDownloadModalState((prev) => ({ ...prev, isOpen }))
  const hasUploadPermission = checkPermission(PermissionType.INVOICES_DISTRIBUTOR_UPLOAD)
  const hasUploadGenericPermission = checkPermission(PermissionType.INVOICES_DISTRIBUTOR_UPLOAD_GENERIC)

  return powerPlants && distributors && !loading ? (
    <InvoicesContainer>
      <DateFilter onChange={(value) => setDateSelected(value)} currentValue={dateSelected} />

      <UploadInvoicesModal openModal={openModal} setOpenModal={setOpenModal} />
      <GenericUploadModal isOpen={isGenericUploadModalOpen} onOpen={setIsGenericUploadModal} />

      <InvoiceExcelModal
        selectedDate={dateSelected}
        openModal={downloadModalState.isOpen}
        setModalOpen={handleToggleModal}
        handleDownload={
          downloadModalState.kind === 'ALL' ?
            handleDownloadAllInvoices :
            handleDownloadPendingInvoices
        }
      />

      <InvoiceCard>
        <InvoiceHeaderCard>
          <InvoiceHeaderTitleCard>Visão geral das faturas</InvoiceHeaderTitleCard>
        </InvoiceHeaderCard>
        <InvoiceCardBody>
          {invoicePercentages && eachPercentage ? (
            <InvoicePercentageBar>
              {!!eachPercentage.completas && (
                <StyledTooltip
                  title={`Faturas carregadas - ${invoicePercentages.completas}`}
                  arrow
                  placement='top'
                >
                  <CompletePayment
                    percentage={eachPercentage.completas}
                    onMouseEnter={() =>
                      setCurrentColor(
                        Theme.colors[InvoiceStatusColor['Completas'] as keyof typeof Theme.colors],
                      )
                    }
                  >
                    {`${eachPercentage.completas}%`}
                  </CompletePayment>
                </StyledTooltip>
              )}
              {!!eachPercentage.aguardando_emissao && (
                <StyledTooltip
                  title={`Faturas aguardando emissão - ${invoicePercentages.aguardando_emissao}`}
                  arrow
                  placement='top'
                >
                  <WaitIssuance
                    percentage={eachPercentage.aguardando_emissao}
                    onMouseEnter={() =>
                      setCurrentColor(
                        Theme.colors[
                        InvoiceStatusColor['Aguardando emissão'] as keyof typeof Theme.colors
                        ],
                      )
                    }
                  >
                    {`${eachPercentage.aguardando_emissao}%`}
                  </WaitIssuance>
                </StyledTooltip>
              )}

              {!!eachPercentage.nao_capturadas && (
                <StyledTooltip
                  title={`Faturas não capturadas - ${invoicePercentages.nao_capturadas}`}
                  arrow
                  placement='top'
                >
                  <NotLoading
                    percentage={eachPercentage.nao_capturadas}
                    onMouseEnter={() =>
                      setCurrentColor(
                        Theme.colors[
                        InvoiceStatusColor['Não capturadas'] as keyof typeof Theme.colors
                        ],
                      )
                    }
                  >
                    {`${eachPercentage.nao_capturadas}%`}
                  </NotLoading>
                </StyledTooltip>
              )}
            </InvoicePercentageBar>
          ) : (
            <InvoicePercentageBar>
              <h4>Não foi possível obter os dados necessários.</h4>
            </InvoicePercentageBar>
          )}
          <InvoiceCardCaptionContainer>
            {colorsCaption.map((props) => (
              <InvoiceCardCaption key={props.title}>
                <ColorCaption color={Theme.colors[props.color]} />
                <PropTitle>{props.title}</PropTitle>
              </InvoiceCardCaption>
            ))}
          </InvoiceCardCaptionContainer>
        </InvoiceCardBody>
      </InvoiceCard>
      {currentPowerPlantId && (
        <ReturnView>
          <ReturnButton
            onClick={() => {
              setPage(1)
              setDistributorInvoices([])
              setCurrentPowerPlantId(undefined)
              setStatus('')
              setSearch('')
              setSortValues(undefined)
            }}
          >
            <ChevronLeft style={{ color: 'white' }} />
          </ReturnButton>
          <ReturnText>{`UCs Beneficiárias | ${currentPowerPlantId.name}`}</ReturnText>
        </ReturnView>
      )}
      <FilterContainer>
        <TableFilters>
          {!currentPowerPlantId && (
            <>
              <FloatingCheckboxes
                isFilterActive={distributors.some((distributor) => distributor.checked)}
                label='Distribuidoras'
                options={distributors}
                selectableAll
                searchable
                submitAction={filterDistributor}
              />
            </>
          )}

          <FloatingFilterWrapper
            isFilterActive={true}
            label='Status'
            modalStyles={{ padding: 4 }}
            hideSubmitButton
          >
            <OptionList showDropDown={true}>
              {[
                { value: 'ATENÇÃO', label: 'Atenção' },
                { value: 'OK', label: 'OK' },
              ].map((item) => (
                <OptionItem
                  active={status == item.value}
                  key={item.value}
                  onClick={() => {
                    setShowDropDown(!showDropDown)
                    status === item.value ? setStatus('') : setStatus(item.value)
                    setPage(1)
                  }}
                >
                  {item.label}
                </OptionItem>
              ))}
            </OptionList>
          </FloatingFilterWrapper>
        </TableFilters>
        <SearchContainer>
          <SearchInput value={search} setValue={setSearch} style={{ width: 180 }} />
          <FloatingCheckboxes
            pagination={{
              selected: pageItemsAmount,
              setSelected: setPageItemsAmount,
              options: [15, 20, 30, 50, 100],
            }}
            isFilterActive={false}
            title='Configurar Tabela'
            options={tableCols.map((col) => ({
              label: col.headerName || '',
              checked: !col.hide,
              id: Math.random(),
            }))}
            customActionComponent={
              <ActionTableButton icon={<SettingsOutlined />}></ActionTableButton>
            }
            submitBtnText='Salvar'
            submitAction={handleSelectTableColumns}
          />

          <DownloadDiv
            onClick={() => handleUpdateDownloadModal('ALL')}
          >
            <img
              src={`${DownloadIcon}`}
              alt='Download todas as faturas'
            />

            <p>
              Todas
            </p>
          </DownloadDiv>

          <DownloadDiv
            onClick={() => handleUpdateDownloadModal('PENDING')}
          >
            <img
              src={`${DownloadIcon}`}
              alt='Download faturas pendentes'
            />

            <p>
              Pendentes
            </p>
          </DownloadDiv>

          {hasUploadGenericPermission &&
            <ActionTableButton icon={<Upload />} onClick={() => setIsGenericUploadModal(true)} styles={{ fontSize: '15px' }}>
              Upload leitor genérico
            </ActionTableButton>
          }
          {hasUploadPermission &&
            <ActionTableButton icon={<Upload />} onClick={() => setOpenModal(true)} styles={{ fontSize: '15px' }}>
              Upload de faturas
            </ActionTableButton>
          }
          <Link
            to='/invoices/history'
            style={{ textDecoration: 'none' }}
            state={{
              distributorsId: distributors.filter((item) => item.checked).map((item) => item.id),
              powerPlantsIds: powerPlants.filter((item) => item.checked).map((item) => item.id),
            }}
          >
            <ActionTableButton icon={<History />} styles={{ fontSize: '15px' }}>Histórico</ActionTableButton>
          </Link>
        </SearchContainer>
      </FilterContainer>
      {!currentPowerPlantId ? (
        <TableV2
          columns={tableCols}
          rows={powerPlantsInvoices ?? []}
          alignText='center'
          messageNoRow='Não há faturas cadastradas.'
          sort={sortValues}
          onSort={(sort) => {
            setSortValues(sort)
            setPage(1)
          }}
          serverSorting
        >
          <TablePagination
            pageInfo={{
              totalCount: powerPlantsTotal,
              currentPage: page,
              totalPages: Math.ceil(powerPlantsTotal / pageItemsAmount),
              limit: pageItemsAmount
            }}
            currentPageItemCount={pageItemsAmount}
            onChange={handlePageChange}
          />
        </TableV2>
      ) : (
        distributorsInvoices && (
          <TableV2
            columns={tableColsDistributor}
            rows={distributorsInvoices ?? []}
            alignText='center'
            messageNoRow='Não há faturas cadastradas.'
            sort={sortValues}
            onSort={(sort) => {
              setSortValues(sort)
              setPage(1)
            }}
            serverSorting
            changeHeaderColor={Theme.colors.green}
          >
            <TablePagination
              pageInfo={{
                totalCount: distributorsTotal,
                currentPage: page,
                totalPages: Math.ceil(distributorsTotal / pageItemsAmount),
                limit: pageItemsAmount
              }}
              currentPageItemCount={pageItemsAmount}
              onChange={handlePageChange}
            />
          </TableV2>
        )
      )}
      <Snackbar
        open={!!errorMessage}
        autoHideDuration={5000}
        onClose={() => setErrorMessage(undefined)}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        sx={{
          width: 400,
        }}
      >
        <Alert
          variant='filled'
          severity='error'
          onClose={() => setErrorMessage(undefined)}
          sx={{
            width: 400,
          }}
        >
          <p>{errorMessage}</p>
        </Alert>
      </Snackbar>
    </InvoicesContainer>
  ) : (
    <Loading />
  )
}
