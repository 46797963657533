import { Pagination, PaginationQuery } from "../interfaces/pagination"
import { convertObjectToQueryParams } from "../utils/pagination"
import { api } from "./api"

export type Alert = {
  option: string
  message: string
  severity: number
  client?: number
  distributor?: number
}

export type AlertResponse = {
  id: number
  expires_at: string
  created_at: string
  client_id?: number
  distributor_id?: number
} & Omit<Alert, 'client' | 'distributor'>

export interface ListWithPagination {
  params: PaginationQuery
  result: Pagination<AlertResponse>
}

export interface Find {
  result: AlertResponse[]
}

class AlertsService {
  async listWithPagination(
    params: ListWithPagination['params'],
  ): Promise<ListWithPagination['result']> {
    const { filter, itemsPerPage, page, sort } = params

    const response = await api.get('/alerts', {
      params: {
        limit: itemsPerPage,
        page,
        ...(filter ? { filter: convertObjectToQueryParams(filter) } : {}),
        ...(sort
          ? {
            field: sort.field,
            order: sort.order,
          }
          : {}),
      },
    })
    return response.data
  }

  async findManyPerUser(): Promise<Find['result']> {
    const response = await api.get(`/alerts/available`)
    return response.data
  }

  async create(params: Alert): Promise<void> {
    await api.post('/alerts', params)
  }

  async edit(id: number, params: Alert): Promise<void> {
    await api.patch(`/alerts/${id}`, params)
  }

  async delete(id: number): Promise<void> {
    await api.delete(`/alerts/${id}`)
  }
}

export const alertsService = new AlertsService()
