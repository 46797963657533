import { NavigateBefore, NavigateNext } from '@mui/icons-material'
import { useState } from 'react'
import { useLocation } from 'react-router-dom'
import { ReactComponent as IlumisolLogo } from '../../assets/ilumisol-logo.svg'
import { ReactComponent as TangiparLogo } from '../../assets/tangipar-logo.svg'
import { useGlobalContext } from '../../context/global/global'
import { LinkButton } from './components/LinkButton'
import { LinkSelect } from './components/LinkSelect'
import {
  Container,
  Footer,
  Logo,
  LogoContainer,
  Nav,
  PartnersContainer,
  ToggleSidebarButton,
  ToggleSidebarButtonContainer,
  Wrapper,
} from './styles'
import { match } from './utils/pathMatch'
import { useRoutesContext } from '../../context/global/sidebar-routes'

export function Sidebar() {
  const { state, Theme } = useGlobalContext()
  const { routes, setRoutes } = useRoutesContext()
  const [isOpen, setIsOpen] = useState(true)

  const typeUser = state.auth?.customer?.type
  const userEmail = state.auth?.email

  const { pathname } = useLocation()

  const hasAlert = (state.alerts?.length ?? 0) > 0

  return (
    <Wrapper isOpen={isOpen} removeRadiusTop={hasAlert}>
      <Container>
        <LogoContainer isOpen={isOpen}>
          <Logo
            src={Theme.logos.fullLogo.toString()}
            isOpen={isOpen}
            {...(Theme.logos.size ? { width: Theme.logos.size } : {})}
          />
          <Logo src={Theme.logos.icon.toString()} isOpen={!isOpen} />
        </LogoContainer>
        <Nav>
          {userEmail && typeUser && routes
            ? routes.map(
              ({ ImgSrc, navText, path, links, isLinkToggled, showLinks }, routeIndex) => {
                if (path === '/payments') {
                  if (userEmail === 'faturas@atuaservicos.com') {
                    return (
                      <LinkButton
                        key={path}
                        path={path}
                        isActive={match(pathname, path)}
                        isOpen={isOpen}
                        Icon={ImgSrc}
                        text={navText}
                      />
                    )
                  }
                }

                if (!showLinks) {
                  return (
                    <LinkButton
                      key={path}
                      path={path}
                      isActive={match(pathname, path)}
                      isOpen={isOpen}
                      Icon={ImgSrc}
                      text={navText}
                    />
                  )
                } else {
                  const linksRemapped = links!
                  const index = linksRemapped.findIndex(({ path }) => (path === '/payments/financial' && (userEmail === 'equipe-sandbox@alkaenergia.com.br' || userEmail === 'equipe@alkaenergia.com.br')) || (path === '/payments/aggregated' && userEmail === 'engenharia@allgreen-solar.com'))

                  if (index !== -1) {
                    linksRemapped.splice(index, 1)
                  }

                  return (
                    <LinkSelect
                      key={path}
                      isOpen={isOpen}
                      Icon={ImgSrc}
                      text={navText}
                      isLinkToggled={isLinkToggled!}
                      links={linksRemapped}
                      onClick={() =>
                        setRoutes((prev) =>
                          prev.map((route, index) => {
                            if (index !== routeIndex) {
                              return route
                            } else {
                              return {
                                ...route,
                                isLinkToggled: !route.isLinkToggled,
                              }
                            }
                          }),
                        )
                      }
                    />
                  )
                }
              },
            )
            : null}
        </Nav>
        <Footer isOpen={isOpen}>
          {userEmail && ['cesup.projetos@bb.com.br'].includes(userEmail) && (
            <PartnersContainer isOpen={isOpen}>
              <IlumisolLogo style={{ width: '100%', height: 'auto' }} />
              <TangiparLogo style={{ width: '100%', height: 'auto' }} />
            </PartnersContainer>
          )}

          <ToggleSidebarButtonContainer title={`botão de ${isOpen ? 'fechar' : 'abrir'}`}>
            <ToggleSidebarButton onClick={() => setIsOpen((prev) => !prev)}>
              {isOpen ? <NavigateBefore /> : <NavigateNext />}
            </ToggleSidebarButton>
          </ToggleSidebarButtonContainer>
        </Footer>
      </Container>
    </Wrapper>
  )
}
