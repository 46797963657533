import { Container, Title, Content, Card, FloatCard } from './styles'
import Edp from '../../assets/edp.svg'
import Copel from '../../assets/copel.svg'
import Energisa from '../../assets/energisa.svg'
import Enel from '../../assets/enel.svg'
import Cemig from '../../assets/cemig.png'
import Cosern from '../../assets/cosern.png'
import Celesc from '../../assets/celesc.png'
import Neoenergia from '../../assets/neoenergia.svg'

import { useEffect, useState } from 'react'
import { ReportEdp } from './Edp-sp'
import { ReportDefault } from './Default'
import { ReportCopel } from './Copel'
import { ReportEnergisa } from './Energisa'
import { ReportEnergisaReader } from './EnergisaReader'
import { CemigDemonstrative } from './CemigDemonstrative'
import { ReportCopelReader } from './CopelReader'
import { ReportEnelReader } from './EnelReader'
import { ReportEDPReader } from './Edp-spReader'
import { ReportEnelAReader } from './EnelAReader'
import { Demonstrative } from './Demonstrative'
import { MultiInvoiceReader } from './MultiInvoiceReader'
import { ReportNeoenergiaReader } from './NeoEnergiaReader'
import { ReportCemigReader } from './CemigReader'

function ReportChoose({ setPage }: { setPage: (value: string) => void }) {
  return (
    <Container>
      <Title>Qual tipo de fatura deseja registrar?</Title>
      <Content>
        <Card onClick={() => setPage('edp-sp-b')}>
          <img src={`${Edp}`} />
          <FloatCard>Grupo B</FloatCard>
        </Card>
        <Card onClick={() => setPage('copel-b')}>
          <img src={`${Copel}`} />
          <FloatCard>Grupo B</FloatCard>
        </Card>
        <Card onClick={() => setPage('energisa-b')}>
          <img src={`${Energisa}`} />
          <FloatCard>Grupo B</FloatCard>
        </Card>
        <Card onClick={() => setPage('default')}>
          <p>Registro padrão</p>
        </Card>
        <Card onClick={() => setPage('energisa-leitor')}>
          <img src={`${Energisa}`} />
          <FloatCard>Leitor</FloatCard>
        </Card>
        <Card onClick={() => setPage('edp-leitor')}>
          <img src={`${Edp}`} />
          <FloatCard>Leitor</FloatCard>
        </Card>
        <Card onClick={() => setPage('copel-leitor')}>
          <img src={`${Copel}`} />
          <FloatCard>Leitor</FloatCard>
        </Card>
        <Card onClick={() => setPage('neoenergia-leitor')}>
          <img src={`${Neoenergia}`} />
          <FloatCard>Leitor</FloatCard>
        </Card>
        <Card onClick={() => setPage('enel-leitor')}>
          <img src={`${Enel}`} />
          <FloatCard>Leitor</FloatCard>
        </Card>
        <Card onClick={() => setPage('cemig-leitor')}>
          <img width={160} src={`${Cemig}`} />
          <FloatCard>Leitor</FloatCard>
        </Card>
        <Card onClick={() => setPage('cemig-demonstrativo')}>
          <img width={160} src={`${Cemig}`} />
          <FloatCard>Leitor Demonstrativo Cemig</FloatCard>
        </Card>
        <Card onClick={() => setPage('cosern-demonstrativo')}>
          <img src={`${Cosern}`} />
          <FloatCard>Leitor Demonstrativo Cosern</FloatCard>
        </Card>
        <Card onClick={() => setPage('celesc-demonstrativo')}>
          <img style={{ width: '130px' }} src={`${Celesc}`} />
          <FloatCard>Leitor Demonstrativo Celesc</FloatCard>
        </Card>
        <Card onClick={() => setPage('leitor-manual')}>
          <p>Leitor manual</p>
        </Card>
      </Content>
    </Container>
  )
}

export function Report() {
  const [page, setPage] = useState('')

  useEffect(() => {
    const storage = localStorage.getItem('report-page')

    if (storage) setPage(storage)
  }, [])

  function goPage(value: string) {
    localStorage.setItem('report-page', value)
    setPage(value)
  }

  switch (page) {
    case 'edp-sp-b': {
      return <ReportEdp setPage={goPage} />
    }
    case 'copel-b': {
      return <ReportCopel setPage={goPage} />
    }
    case 'energisa-b': {
      return <ReportEnergisa setPage={goPage} />
    }
    case 'energisa-leitor': {
      return <MultiInvoiceReader setPage={goPage} reader='azure' />
    }
    case 'edp-leitor': {
      return <MultiInvoiceReader setPage={goPage} reader='azure' />
    }
    case 'copel-leitor': {
      return <ReportCopelReader setPage={goPage} />
    }
    case 'neoenergia-leitor': {
      return <MultiInvoiceReader setPage={goPage} reader='azure' />
    }
    case 'enel-leitor': {
      return <ReportEnelReader setPage={goPage} />
    }
    case 'cemig-leitor': {
      return <MultiInvoiceReader setPage={goPage} reader='azure' />
    }
    case 'cemig-demonstrativo': {
      return <CemigDemonstrative setPage={goPage} />
    }
    case 'cosern-demonstrativo': {
      return <Demonstrative distributorName="cosern" setPage={goPage} />
    }
    case 'celesc-demonstrativo': {
      return <Demonstrative distributorName="celesc" setPage={goPage} />
    }
    case 'leitor-manual': {
      return <MultiInvoiceReader setPage={goPage} />
    }
    case 'default': {
      return <ReportDefault setPage={goPage} />
    }
    default: {
      return <ReportChoose setPage={goPage} />
    }
  }
}
